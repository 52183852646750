import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';

import React, { useState, useEffect } from 'react';
import {PageInfo, ClickInfo} from '../Maritime/MaritimeState'
import {CardPortal, DeckLayers, HoverInfo, isDataLoading} from '../GlobalState'
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Portal from '@mui/base/Portal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import chroma from 'chroma-js'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';

import { NORAD_list } from './NoradIds';
import { GroundStationLocations } from './SatelliteState';

import {GeoJsonLayer, IconLayer, PathLayer} from '@deck.gl/layers';
import { Stack } from '@mui/system';
import { getEpochTimestamp, getGroundTracksSync, getLatLngObj, getSatelliteName } from "tle.js";
import satellite from 'data-url:./satellite.svg'

const ICON_MAPPING = {
    satellite: {x:0, y:0, width:410, height:410, mask:true}
  }

let getSatellitePositions= async ({setSatellitePositions, setIsDataLoading})=>{
    setIsDataLoading(true)
    for (const norad of NORAD_list){
        try {
            let request = await fetch(`https://api.spireglobal.com/tle/${norad}`)
            let tle = await request.text()
            
            let [line0, line1, line2] = tle.split('\n')

            let satInfo = {name: getSatelliteName([line0,line1,line2]),
                            initialPosition:getLatLngObj([line0,line1,line2]), 
                            time: new Date(getEpochTimestamp([line0,line1,line2])).toISOString(), 
                            tle,
                            getCurrentPosition: ()=>{
                                let [line0, line1, line2] = tle.split('\n')
                                return getLatLngObj([line0,line1,line2])
                                },
                            getGroundTracks: ()=>{
                                return getGroundTracksSync({tle:[line0,line1,line2], stepMS: 1000*60})
                            },
                            enabled:true
                        }

            setSatellitePositions(prev => {
                let state = {...prev}
                state[satInfo.name] = satInfo
                return state
            })
        } catch (e) {
            console.log(`Error Loading Satellite: ${e}`)
        }
    }
    setIsDataLoading(false)
}

let getSatelliteLayer= ({data, setHoverInfo})=> {
    let satelliteLayer = new IconLayer({
        id: 'satellite-layer',
        data,
        pickable: true,
        iconAtlas: satellite,
        iconMapping: ICON_MAPPING,
        sizeMaxPixels: 25,
        sizeMinPixels: 15, 
        sizeScale:8,
        getIcon: d => 'satellite',
        getPosition: d => {let {lat,lng} = d.getCurrentPosition(); return [lng,lat, 15]},
        getColor: d => [0,0,255] ,
        onHover: (info, event) => {
            if(info.object) {
                info.object.message = info.object.name; 
                setHoverInfo(info)
            } else {
                setHoverInfo(info)
            }
          
        }
    });

return satelliteLayer
}

let getOrbitLayer= ({data, setHoverInfo}) => {
    let orbitLayer = new PathLayer({
        id: 'satellite-orbit-layer',
        data,
        pickable: true,
        widthScale: 20,
        widthMinPixels: 2,
        positionFormat: 'XY',
        //opacity: d => .5,
        getPath: d =>  d.getGroundTracks()[1],
        getColor: d => [0,0,255],
        getWidth: d => 5,
        onHover: (info, event) => {
            if(info.object) {
                info.object.message = info.object.name; 
                setHoverInfo(info)
            } else {
                setHoverInfo(info)
            }
          
        }
    });

    return orbitLayer
}




  let SatelliteDataComponent= ()=>{
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [displaySatelliteOptions, setDisplaySatelliteOptions] = useState(false)
    const [viewSatelliteLayer, setViewSatelliteLayer] = useState(false)
    const [satellitePositions, setSatellitePositions] = useState([])
    const [orbitLayer, setOrbitLayer] = useState(false)
    const setIsDataLoading = useSetRecoilState(isDataLoading)
    const [currentTime, setCurrentTime] = useState(0)
    const [nameFilter, setNameFilter] = useState('')
    const [locationFilter, setLocationFilter] = useState('')
    const [intId, setIntId] = useState(0)
    const [startText, setStartText] = useState(new Date().toISOString())
    const [satelliteGroup, setSatelliteGroup] = useState('all-satellites')
    const [locations, setLocations] = useRecoilState(GroundStationLocations)

    let handleLiveUpdates = ()=>{
        if(currentTime == 0){  
            setIntId(()=> setInterval(()=> setCurrentTime(new Date().toISOString()),1000))
        } else {
            clearInterval(intId)
            setCurrentTime(0)
        }
    }

    let handleSelectSatellite = (d) => {
        if(d.enabled) {
            d.enabled = false
            setSatellitePositions(prev => {
                let next = {...prev}
                next[d.name] = d
                return next
            })
        } else {
            d.enabled = true
            setSatellitePositions(prev => {
                let next = {...prev}
                next[d.name] = d
                return next
            })
        }
    }

    let handleSelectALL = ()=> {
        setSatellitePositions(prev => {
            let next = {...prev}
            for (const sat of Object.values(next)) {
                sat.enabled = true
                next[sat.name] = sat
            }
            return next
        })
    }
    
    let handleDeselectAll = ()=> {
            setSatellitePositions(prev => {
                let next = {...prev}
                for (const sat of Object.values(next)) {
                    sat.enabled = false
                    next[sat.name] = sat
                }
                return next
            })
        }

        let handleLocationSelectALL = ()=> {
            setLocations(prev => {
                let next = {...prev}
                for (const sat of Object.values(next)) {
                    sat.enabled = true
                    next[sat.name] = sat
                }
                return next
            })
        }

        let handleLocationDeselectAll = ()=> {
            setLocations(prev => {
                let next = {...prev}
                for (const sat of Object.values(next)) {
                    sat.enabled = false
                    next[sat.name] = sat
                }
                return next
            })
        }


    let handleFilterChange = (event)=>{
        setNameFilter(event.target.value)
    }

    let handleLocationFilterChange = (event)=>{
        setLocationFilter(event.target.value)
    }
    


    useEffect(()=>{
        if(viewSatelliteLayer & satellitePositions.length == 0){
            getSatellitePositions({setSatellitePositions, setIsDataLoading})
        }
        
        
        if(Object.values(satellitePositions).length > 0 & viewSatelliteLayer){            
            setLayers((prev)=> [...prev.filter(d => d.id != 'satellite-layer'), getSatelliteLayer({data:Object.values(satellitePositions).filter(d=> d.enabled), setHoverInfo, setIsDataLoading})]);
        } else {
            setLayers((prev) => prev.filter(d => d.id != 'satellite-layer')); 
        }
    }, [viewSatelliteLayer, satellitePositions, currentTime])

    useEffect(()=>{
        if(Object.values(satellitePositions).length > 0 & orbitLayer){            
            setLayers((prev)=> [...prev.filter(d => d.id != 'satellite-orbit-layer'), getOrbitLayer({data:Object.values(satellitePositions).filter(d=> d.enabled), setHoverInfo})]);
        } else {
            setLayers((prev) => prev.filter(d => d.id != 'satellite-orbit-layer')); 
        }
    }, [orbitLayer, satellitePositions, currentTime])


    let regex = new RegExp(`.*${nameFilter != '' ? nameFilter.toLowerCase() : '.' }*`)

    let locationRegex = new RegExp(`.*${locationFilter != '' ? locationFilter.toLowerCase() : '.' }*`)

   return (
     <React.Fragment>
        <Button variant="text" color="secondary" onClick={()=> setDisplaySatelliteOptions(prev => !prev)}>Spire Satellites</Button>
        {cardPortal && displaySatelliteOptions &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300, order:-2}}>
            <CardContent>
              <Stack spacing={3}>
              <FormControl variant="outlined">
                    <InputLabel htmlFor="start">Start Date</InputLabel>
                    <OutlinedInput 
                        id="start"
                        onChange={(event)=> setStartText(event.target.value)}
                        value={startText}
                    />
                </FormControl>
                <FormGroup> 
                <InputLabel id="demo-simple-select-label">Satellite Group</InputLabel>
                <Select
                    value={satelliteGroup}
                    label="Satellite Group"
                    onChange={(event)=>setSatelliteGroup(event.target.value)}
                  >
                    <MenuItem value="all-satellites">All Satellites</MenuItem>
                    {/*<MenuItem value="no-route-available">No Route Available</MenuItem>
                    <MenuItem value="all-vessels">All Vessels</MenuItem>*/}
                  </Select> 
                  </FormGroup>
                </Stack>
            </CardContent>
            </Card>
          </Portal> 
          }
        
        {cardPortal && displaySatelliteOptions &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
            <TextField id="outlined-basic" label="Location Filter" variant="standard" value={locationFilter} onChange={handleLocationFilterChange} fullWidth/>
            <List sx={{overflowY:'auto', minHeight:`150px`}}>
                {Object.values(locations).length > 0 && Object.values(locations).filter(d => locationRegex.test(d.name.toLowerCase())).map((d,i)=> (
                                    
                                    <ListItem key={i} disablePadding
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={()=>handleSelectLocations(d)}
                                        checked={d.enabled}
                                      />
                                    }>
                                      <ListItemButton>
                                        <ListItemText primary={d.name} secondary={`Lat: ${Math.round(d.location[1]*1000)/1000}°  / Long: ${Math.round(d.location[0]*1000)/1000}°`} onClick={()=>console.log(d)}/>
                                      </ListItemButton>
                                    </ListItem>
              ))}
            </List> 
            </CardContent>
            <CardActions>
                <Button onClick={handleDeselectAll}>Deselect All</Button>
                <Button onClick={handleSelectALL}>Select All</Button>
            </CardActions>
            </Card>
          </Portal> 
          }

        {cardPortal && displaySatelliteOptions &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
            <Stack direction="row">
            <FormControlLabel control={
                  <Switch
                    checked={viewSatelliteLayer}
                    onChange={()=> setViewSatelliteLayer(prev => !prev)}
                    color='info'
                    
                  />
                } label="Spire Satellite Layer" />
                <FormControlLabel control={
                  <Switch
                    checked={currentTime != 0}
                    onChange={handleLiveUpdates}
                    color='info'
                    
                  />
                } label="Live Updates" />
            </Stack>
            <TextField id="outlined-basic" label="Satellite Filter" variant="standard" value={nameFilter} onChange={handleFilterChange} fullWidth/>
            <List sx={{overflowY:'auto', height:`300px`}}>
                {Object.values(satellitePositions).length > 0 && Object.values(satellitePositions).filter(d => regex.test(d.name.toLowerCase())).map((d,i)=> (
                                    
                                    <ListItem key={i} disablePadding
                                    secondaryAction={
                                      <Checkbox
                                        edge="end"
                                        onChange={()=>handleSelectSatellite(d)}
                                        checked={d.enabled}
                                      />
                                    }>
                                      <ListItemButton>
                                        <ListItemText primary={d.name} onClick={()=>console.log(d)}/>
                                      </ListItemButton>
                                    </ListItem>
              ))}
            </List> 
            </CardContent>
            <CardActions>
                <Button onClick={handleDeselectAll}>Deselect All</Button>
                <Button onClick={handleSelectALL}>Select All</Button>
                <Button onClick={()=>setSatellitePositions([]) }>Clear TLEs</Button>
                {!orbitLayer ? <Button onClick={()=> setOrbitLayer(prev=> !prev)}>View Orbits</Button> : <Button onClick={()=> setOrbitLayer(prev=> !prev)}>Hide Orbits</Button>}
            </CardActions>
            </Card>
          </Portal> 
          }
          
          </React.Fragment>)
  } 

  export {SatelliteDataComponent} 