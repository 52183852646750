import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';




const MaritimeGWSClickInfo = atom({
    key: 'MaritimeGWSClickInfo',
    default: [],
  }); 

const MaritimeGWSCQLFilter = atom({
  key: 'MaritimeGWSCQLFilter',
  default: '',
}); 

const MaritimeGWSLayers = atom({
  key: 'MaritimeGWSLayers',
  default: [],
}); 

const MaritimeGWSCapabilities = atom({
  key: 'MaritimeGWSCapabilities',
  default: null,
}); 

const MaritimeGWSStyles = atom({
  key: 'MaritimeGWSStyles',
  default: {},
}); 

export {MaritimeGWSClickInfo,MaritimeGWSCQLFilter, MaritimeGWSLayers, MaritimeGWSCapabilities, MaritimeGWSStyles}