import { along, lineString, point, lineSlice } from "@turf/turf"
import {ScatterplotLayer, LineLayer} from '@deck.gl/layers';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import chroma from "chroma-js";
import { useRecoilState } from "recoil";
import { MaritimeInsights } from "./WeatherState";

let colors = {
    red: chroma('red').rgb(),
    amber: chroma('yellow').rgb(),
    green: chroma('green').rgb(),
    orange: chroma('orange').rgb(),
    grey: chroma('grey').rgb(),
    "light green": chroma("green").brighten(2).rgb()
}

let RouteWeatherForecastLayer = ({routeWXForecast, setCurrentWeatherConditions, setHoverInfo})=>{
    let data = routeWXForecast.map(d=> d.forecast).map(d =>d.data).flat();

    const newlayer = 
    new ScatterplotLayer({id: 'weather-route-layer', 
                data: data,
                pickable: true,
                stroked: true,
                filled: true,
                radiusScale: 6,
                radiusMinPixels: 2,
                radiusMaxPixels: 100,
                lineWidthMinPixels: 1,
                getFillColor: d => [0,0,0],
                getLineColor: d => [0,0,0],
                getPosition: value => [value.location.coordinates.lon, value.location.coordinates.lat], 
                onClick: (info, event) => setCurrentWeatherConditions({meta:{forecast:'Route Forecast'}, data:[info.object]}),
                onHover: (info, event) => setHoverInfo(info)
              })
    return newlayer;
}

let RouteMaritimeInsightsLayer = ({routeMaritimeInsights, setMaritimeInsights, setHoverInfo})=>{
    let data = routeMaritimeInsights.map(d => d.forecast).map(d => d.data).flat();

    const newlayer = 
    new LineLayer({id: 'weather-insights-layer', 
                data: data,
                pickable: true,
                stroked: true,
                filled: true,
                getWidth: 2,
                getSourcePosition: value => [value.start.lon, value.start.lat], 
                getTargetPosition: value => [value.end.lon, value.end.lat],
                getColor: d => colors[d.combined_risk], 
                onHover: (info, event) => setHoverInfo(info),
                onClick: (info, event) => setMaritimeInsights(info.object)
              })
    return newlayer;
}


let getRouteWXForecast = async ({clickInfo, weatherAPIToken, setRouteWXForecast, setIsDataLoading, insights=null}) => {
    clickInfo.forEach(async (vessel)=> {
        if (vessel.currentVoyage.predictedRoute.waypoints.geoJson.coordinates){
            let route= vessel.currentVoyage.predictedRoute.waypoints.geoJson.coordinates
            let predictedRoute = lineString(route)

            let name = vessel.staticData.name
            let mmsi = vessel.staticData.mmsi

            let speed = vessel.lastPositionUpdate.speed
            let lastUpdate = new Date(vessel.lastPositionUpdate.timestamp)
            let predictedEta = new Date(vessel.currentVoyage.predictedRoute.eta)

            let currentPosition = point([vessel.lastPositionUpdate.longitude,vessel.lastPositionUpdate.latitude])
            let predictedWeatherRoute = lineSlice(currentPosition, point(route.slice(-1)[0]), predictedRoute)

            let weatherRoute = []

            while (lastUpdate <= predictedEta){
                let point = along(predictedWeatherRoute,speed*(weatherRoute.length+1)*1.85200,{units:'kilometers'})

                let time = lastUpdate.getFullYear() + "-" + ("0"+(lastUpdate.getMonth()+1)).slice(-2) + "-" + ("0" + lastUpdate.getDate()).slice(-2) + "T" + ("0" + lastUpdate.getHours()).slice(-2) + ":" + ("0" + lastUpdate.getMinutes()).slice(-2) + ":" +  ("0" + lastUpdate.getSeconds()).slice(-2);
                
                weatherRoute.push({lat:point.geometry.coordinates[1], lon:point.geometry.coordinates[0], time})

                lastUpdate.setHours(lastUpdate.getHours()+1)
            }
            
            if (insights == null){
                let postBody = {route:{name, waypoints:weatherRoute.slice(0,120)}, bundles:"basic,maritime"}
                setIsDataLoading(true)

                try {

                    let forecastRequest = await fetch(`/forecast/route`, {
                        method:"POST",
                        headers:{
                            'spire-api-key': weatherAPIToken,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(postBody)
                    })


                    let forecast = await forecastRequest.json()

                    setRouteWXForecast((prev)=> {
                        let newState = structuredClone(prev)
                        newState = [...newState.filter(d=> d.mmsi != mmsi),{mmsi,forecast}]
                        return newState
                    })
                    setIsDataLoading(false)
                    
                } catch {
                    console.log(`Error Fetching Weather Forecast for Route`)
                    setIsDataLoading(false)
                }
            } else if (insights != null){
                try {
                    let {maritimeRiskType, maritimeInsightsVesselTypeSelection} = insights
                    let postBody = {route:{waypoints:weatherRoute.slice(0,120)}, vessel: maritimeInsightsVesselTypeSelection}
                    setIsDataLoading(true)

                    let forecastRequest = await fetch(`/insights/maritime/route?type=${maritimeRiskType}`, {
                        method:"POST",
                        headers:{
                            'spire-api-key': weatherAPIToken,
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify(postBody)
                    })


                    let forecast = await forecastRequest.json()

                    console.dir(forecast)

                    setRouteWXForecast((prev)=> {
                        let newState = structuredClone(prev)
                        newState = [...newState.filter(d=> d.mmsi != mmsi),{mmsi,forecast}]
                        return newState
                    })
                    setIsDataLoading(false)
                    
                } catch {
                    console.log(`Error Fetching Maritime Insights for Route`)
                    setIsDataLoading(false)
                }

            }
        } else {
            console.log(`Predicted Route Not Available`)
        }
    })
}


let MaritimeInsightsCard = ()=>{
    const [maritimeInsights, setMaritimeInsights] = useRecoilState(MaritimeInsights)
    let {start, end, combined_risk, risk_factors} = maritimeInsights

    return (
        <Card sx={{overflow:'hidden', maxWidth:600}}>
            <CardContent>
            <TableContainer sx={{ minWidth: 600, maxHeight:550}}>
            <Table stickyHeader>
                <TableHead>
                <TableRow>
                    <TableCell colSpan={4} align='center'> Combined Risk: {combined_risk}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={42} align='center'>Start: {start.time} <br/>End: {end.time} </TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Risk</TableCell>
                    <TableCell align="left">Risk Factor</TableCell>
                    <TableCell align="left">Level</TableCell>
                    <TableCell align="left">Description</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {Object.keys(risk_factors).map(key => (
                    Object.entries(risk_factors[key]).map(([k,v]) =>
                    v['reasons'].map(el => 
                    <TableRow
                    key={feature+k+v+el}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {key}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {k}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {v['risk']}
                    </TableCell>
                    <TableCell component="th" scope="row">
                        {el}
                    </TableCell>
                    </TableRow>)))
                )}
                </TableBody>
            </Table>
            </TableContainer>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button onClick={()=> setMaritimeInsights(null)}>Close</Button>
            </CardActions>
        </Card>
      )
    } 






export {getRouteWXForecast, RouteWeatherForecastLayer,RouteMaritimeInsightsLayer,MaritimeInsightsCard}
