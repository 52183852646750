import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {ClickInfo, MaritimeHistoryData, MaritimeData, MaritimePredictedRouteData, HistoryStart, HistoryEnd} from './MaritimeState'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MaritimeAPIToken, ViewState, DeckLayers, DGVAPIToken, isDataLoading, WeatherAPIToken, FeatureEnhancedVC, FeatureHistoricalPositions, FeatureVesselToPortETA } from '../GlobalState';
import { getMaritimeHistoryData, getMaritimeHistoryLayer } from './MaritimeHistory';
import { getMaritimeData} from './MaritimeInitialQuery';
import { DGVEventData } from '../DGV/DGVState';
import { getDGVEvents } from '../DGV/DGVEvents';
import { Stack } from '@mui/system';

import {getRouteWXForecast} from '../Weather/RouteWXForecast'
import { RouteWeatherConditions, RouteMaritimeInsights, MaritimeInsightsVesselTypeSelection, MaritimeInsightsType } from '../Weather/WeatherState';




export default function MaritimeCard() {
    const [clickInfo, setClickInfo] = useRecoilState(ClickInfo);
    const [viewState, setViewState] = useRecoilState(ViewState);
    const [tabValue, setTabValue] = React.useState(0);
    const [maritimeHistoryData, setMaritimeHistoryData] = useRecoilState(MaritimeHistoryData)
    const [maritimePredictedRouteData, setMaritimePredictedRouteData] = useRecoilState(MaritimePredictedRouteData)
    const [maritimeAPIToken] = useRecoilState(MaritimeAPIToken)
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [maritimeData,setMaritimeData] = useRecoilState(MaritimeData);
    const setDGVEventData = useSetRecoilState(DGVEventData)
    const dgvAPIToken = useRecoilValue(DGVAPIToken)
    const weatherAPIToken = useRecoilValue(WeatherAPIToken)
    const setIsDataLoading = useSetRecoilState(isDataLoading)
    const [routeWXForecast,setRouteWXForecast] = useRecoilState(RouteWeatherConditions)
    const [routeMaritimeInsights,setRouteMaritimeInsights] = useRecoilState(RouteMaritimeInsights)
    const maritimeInsightsVesselTypeSelection = useRecoilValue(MaritimeInsightsVesselTypeSelection)
    const maritimeRiskType = useRecoilValue(MaritimeInsightsType)
    const start = useRecoilValue(HistoryStart)
    const end = useRecoilValue(HistoryEnd)
    const featureHistoricalPositions = useRecoilValue(FeatureHistoricalPositions)
    const featureEnhancedVC = useRecoilValue(FeatureEnhancedVC)
    const featureVesselToPortEta = useRecoilValue(FeatureVesselToPortETA)


    let handleChange = (event,  newValue)=>{
        setTabValue(newValue)
    }

    let handleZoom = ()=>{
        setViewState((prev)=>{
            let target = clickInfo[tabValue]
            return {
                longitude: target.lastPositionUpdate.longitude,
                latitude: target.lastPositionUpdate.latitude,
                zoom: 6,
                pitch: prev.pitch,
                bearing: prev.bearing}
        })
    }

    let handleHistoryRequest = ()=>{
        let dates = {start,end}
        getMaritimeHistoryData({clickInfo, setMaritimeHistoryData, maritimeAPIToken,dates});
    }

    let handlePredictedRouteRequest = ()=>{
        let searchParameters = {
          mmsi: clickInfo.map(d => d.staticData.mmsi)
        }


        getMaritimeData({setMaritimeData,maritimeAPIToken,searchParameters,vesselToPortETA:true, route:true}).then(data => {
          setMaritimePredictedRouteData(data)
          setClickInfo(prev => [...prev.filter(d =>d.mmsi), ...data])
        })
    }

    let handleDGVEventRequest = () => {
        setIsDataLoading(true)
        getDGVEvents({
          mmsis:clickInfo.map(d=> d.staticData.mmsi).join(','),
          setDGVEventData,
          dgvAPIToken
        }).then(()=> setIsDataLoading(false))
    }

    let handleGetRouteWXForecast = () => {
        getRouteWXForecast({clickInfo, weatherAPIToken, setRouteWXForecast, setIsDataLoading})
    }

    let handleGetMaritimeInsights =() => {
      getRouteWXForecast({clickInfo, weatherAPIToken, setRouteWXForecast:setRouteMaritimeInsights, setIsDataLoading,insights:{maritimeRiskType, maritimeInsightsVesselTypeSelection}})
    }







    let data = clickInfo.map((data)=>{
        let d = structuredClone(data)
        for (key of Object.keys(d)){
            if (typeof(d[key]) == 'object' && d[key]) {
                for (feature of Object.keys(d[key])) {
                    if (typeof(d[key][feature]) != 'object'){
                      d[key+'-'+feature] = d[key][feature]
                    }  else {
                      if (d[key][feature]){
                        for (feature2 of Object.keys(d[key][feature])) {
                          if (d[key][feature][feature2]){
                            d[key+'-'+feature+'-'+feature2] = typeof(d[key][feature][feature2]) == 'object' ? JSON.stringify(Object.values(d[key][feature][feature2])[0]) : d[key][feature][feature2]
                          }
                        }
                      }  
                    } 
                }
            }
            delete d[key];
        }
        return d;
})


    return (
        <Card sx={{overflow:'hidden', maxWidth:500}}>
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile sx={{maxWidth:500}}>
            {data.map((d, i) => <Tab label={d['staticData-name']} value={i} key={i} /> )}
          </Tabs>
          </Box>
          {data.map((d,i)=> (
          <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
          <TableContainer sx={{ minWidth: 250, maxHeight:300}}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell colSpan={2} align='center'>MMSI: {d['staticData-mmsi']} {d['staticData-imo'] ? "/ IMO:" + d['staticData-imo'] : null}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Feature</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(d).map(([feature,value]) => 
              (
                <TableRow
                  key={feature}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {feature}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box> ))}
        </CardContent>
        <CardActions sx={{flexDirection:'column'}}>
            <Stack direction='row'>
              { featureHistoricalPositions ? Object.keys(maritimeHistoryData).length == 0 ? <Button onClick={handleHistoryRequest}>Get History</Button> : <Button onClick={()=> setMaritimeHistoryData({})}>Clear History</Button>: ''}
            { featureVesselToPortEta ? Object.keys(maritimePredictedRouteData).length == 0 ?<Button onClick={handlePredictedRouteRequest}>Get Predicted Route</Button> : <Button onClick={() => setMaritimePredictedRouteData({})}>Clear Predicted Route</Button> : ''}
            </Stack>
            {weatherAPIToken &&
            <Stack direction='row'>
            {routeWXForecast.length == 0 ? <Button onClick={handleGetRouteWXForecast}>Get Route Wx Forecast</Button> :<Button onClick={()=> setRouteWXForecast([])}>Clear Route Wx Forecast</Button>}
            {routeMaritimeInsights.length == 0 ? <Button onClick={handleGetMaritimeInsights}>Get Route Maritime Insights</Button>:<Button onClick={()=> setRouteMaritimeInsights([])}>Clear Maritime Insights</Button>}
            </Stack> }
            <Stack direction='row'>
            <Button onClick={()=> setClickInfo([])}>Close</Button>
            <Button onClick={handleZoom}>Zoom to target</Button>
            {dgvAPIToken ? <Button onClick={handleDGVEventRequest}>Get DGV Events</Button> : ''}
            </Stack>
        </CardActions>
        </Card>
      )
}