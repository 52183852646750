import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import { DGVClickInfo, DGVEventData, DGVEventPlayback, DGVTargetLayerVisibility } from './DGVState';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MaritimeAPIToken, ViewState } from '../GlobalState';
import { colorDGVTargets } from './DGVQuery';
import {IconLayer, LineLayer} from '@deck.gl/layers';
import vessel from 'data-url:../Maritime/vessel.svg'
import {DeckLayers} from '../GlobalState'
import { getDGVEvents } from './DGVEvents';
import {DGVAPIToken, HoverInfo, isDataLoading} from '../GlobalState'
import { getMaritimeData } from '../Maritime/MaritimeInitialQuery';
import { ClickInfo } from '../Maritime/MaritimeState';
import { MaritimeData } from '../Maritime/MaritimeState';
import { Stack } from '@mui/material';





let DGVCard = ({data}) => {
    const [dgvClickInfo, setDGVClickInfo] = useRecoilState(DGVClickInfo);
    const [tabValue, setTabValue] = React.useState(0);
    const [viewState, setViewState] = useRecoilState(ViewState);
    const [dgvEventData, setDGVEventData] = useRecoilState(DGVEventData)
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [dgvAPIToken] = useRecoilState(DGVAPIToken)
    const [layerVisibility, setLayerVisibilty] = useRecoilState(DGVTargetLayerVisibility)
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [isLoading,setIsLoading] = useRecoilState(isDataLoading)
    const setMaritimeData = useSetRecoilState(MaritimeData);
    const maritimeAPIToken = useRecoilValue(MaritimeAPIToken);
    const setClickInfo = useSetRecoilState(ClickInfo)
    const [dgvEventPlayback,enableDGVPlayback] = useRecoilState(DGVEventPlayback)

    let handleDGVEventPlayback = () => {
        enableDGVPlayback((prev)=> !prev)
    }

    let handleChange = (event,  newValue)=>{
      setTabValue(newValue)
    }

    let handleZoom = ()=>{
        setViewState((prev)=>{
            let target = dgvClickInfo[tabValue]
            return {
                longitude: target.longitude,
                latitude: target.latitude,
                zoom: 6,
                pitch: prev.pitch,
                bearing: prev.bearing}
        })
    }

    let handleGetDGVEvents = ()=> {
      setIsLoading(true)
      getDGVEvents({mmsis:dgvClickInfo.map(d=>d.mmsi).join(','), setDGVEventData,dgvAPIToken}).then(()=> setIsLoading(false))
      
    }

    

    let handleMaritimeDataRequest = () => {
      let searchParameters = {
        mmsi:dgvClickInfo.map(d=> d.mmsi).join(',')
      }
      getMaritimeData({setMaritimeData,maritimeAPIToken,searchParameters, evd:true}).then(
        (data)=> {
          setClickInfo((prev)=>{
            let prevData = structuredClone(prev);
            if (data.length >= 1){
                return [...prevData, ...data]
            } else {
                return prevData
            }           
          })
        }
      )
    }

 


    let rows = dgvClickInfo

    return (
      <Card sx={{overflow:'hidden'}}>
      <CardContent>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange}>
          {rows.map((d, i) => <Tab label={d.vessel_name} value={i} key={i} /> )}
        </Tabs>
        </Box>
        {rows.map((d,i)=> (
        <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
        <TableContainer sx={{ minWidth: 250, maxHeight:300}}>
        <Table stickyHeader aria-label="simple table">
          <TableHead>
          <TableRow>
              <TableCell colSpan={2} align='center'>MMSI: {d.mmsi} {d.imo ? "/ IMO:" + d.imo : null}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell align="right">Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            
            {Object.entries(d).map(([feature,value]) => {
            

            return (<TableRow
                key={feature}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {feature}
                </TableCell>
                <TableCell align="right">{typeof(value) == 'string' || typeof(value) == 'number'  ? value : typeof(value)}</TableCell>
              </TableRow>
            )}
            )
              }
        
          </TableBody>
        </Table>
      </TableContainer>
            </Box> ))}
      </CardContent>
      <CardActions sx={{flexDirection:'column'}}>
        <Stack direction='row'>
          <Button onClick={handleGetDGVEvents}>Get DGV Events</Button>
          <Button onClick={()=> {setDGVEventData([])}}>Clear DGV Events</Button>
          <Button onClick={handleDGVEventPlayback}>{!dgvEventPlayback ? 'Start DGV Event Playback' : 'Stop DGV Event Playback'}</Button>
        </Stack>
        <Stack direction='row'>
          <Button onClick={()=> setDGVClickInfo([])}>Close</Button>
          <Button onClick={handleZoom}>Zoom to target</Button>
          {maritimeAPIToken ? <Button onClick={handleMaritimeDataRequest}>Get M2.0 Data</Button> : ''}
        </Stack>
      </CardActions>
      </Card>
    )
    }

export {DGVCard}