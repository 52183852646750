import React, { useEffect } from 'react';
import { DeckLayers, HoverInfo, ViewState } from '../GlobalState';
import {
  EditableGeoJsonLayer,
} from '@nebula.gl/layers';
import { useRecoilState } from 'recoil';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { CardPortal } from '../GlobalState';
import Portal from '@mui/base/Portal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';
import { Stack } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import SyntaxHighlighter from 'react-syntax-highlighter';

import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import FolderIcon from '@mui/icons-material/Folder';
import DeleteIcon from '@mui/icons-material/Delete';

import GeoJSON from 'ol/format/GeoJSON.js';
import WFS from 'ol/format/WFS.js'
import WKT from 'ol/format/WKT.js'
import KML from 'ol/format/KML.js'


import {
  ViewMode,
  DrawRectangleMode,
  ExtrudeMode,
  ScaleMode,
  DrawPolygonMode,
  ModifyMode,
  ViewMode
} from '@nebula.gl/edit-modes';

import { FeatureLibrary, Features } from './EditState';





const GeometryEditor = () => {
  const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
  const [features, setFeatures] = React.useState({"type":"FeatureCollection","features":[]})
  const [featureFormat, setFeatureFormat] = React.useState('feature-collection');
  const [featureLibrary, setFeatureLibrary] = useRecoilState(FeatureLibrary)
  const [mode, setMode] = React.useState(()=>DrawRectangleMode);
  const [modeConfig, setModeConfig] = React.useState({});
  const [managePolygons, setManagePolyons] = React.useState(false)
  const [selectedFeIndex, setSelectedFeIndex] = React.useState(0)
  const [featureCollectionName, setFeatureCollectionName] = React.useState("Unnamed Feature Collection")
  const [selectedIndex, setSelectedIndex] = React.useState(null);
  const selectedFeatureIndexes = [selectedFeIndex]
  let selectedFeatureCollectionText = ''
  

  const [layer, setLayers] = useRecoilState(DeckLayers)


  let [isEditGeography,setIsEditGeography] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = React.useState(null);
  const open = Boolean(anchorEl);
  const open2 = Boolean(anchorEl2);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick2 = (event) => {
    setAnchorEl2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorEl2(null);
  };

  const handleSetFeatureLibrary = ()=> {
    setFeatureLibrary(prev => {
      let fl = structuredClone(prev);
      fl[featureCollectionName] = features
      return fl
    })
  }

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    setFeatureCollectionName(Object.keys(featureLibrary)[index])
    setFeatures(Object.values(featureLibrary)[index])
  };

  let handleDeleteFeatureCollection = ()=> {
    setFeatureLibrary(prev=> {
      let fc = structuredClone(prev)
      delete fc[Object.keys(featureLibrary)[selectedIndex]]
      return fc
    })
  }

  let handleDeleteSelected = () => {
    setFeatures(prev => {
        let fc = structuredClone(prev)
        fc['features'] = [...prev['features'].filter(d=> d != prev['features'][selectedFeIndex] )]
        return fc
    })
  }

  const handleSetFeatureFormat = (event, newFormat) => {
    setFeatureFormat(newFormat);
  };

  let handleTextFieldChange = (event)=> {
    let newFeatures = event.target.value

    
    try {

      switch(featureFormat) {
        case "feature-collection":
          newFeatures = new GeoJSON().writeFeatures(GeoJSON().readFeatures(newFeatures))
          break;
        case "WKT":
          newFeatures = new GeoJSON().writeFeatures(new WKT().readFeatures(newFeatures))
          break;
        case "geojson":
          newFeatures = new GeoJSON().writeFeatures(new GeoJSON().readGeometry(newFeatures))
          break;
        case "kml":
          newFeatures = new GeoJSON().writeFeatures(new KML().readFeatures(newFeatures))
          break;
      }

      console.dir({newFeatures, geojson: new GeoJSON(),featureFormat})

      setFeatureLibrary(prev=> {
        let fc = structuredClone(prev)
        fc[Object.keys(featureLibrary)[selectedIndex]] =newFeatures
        return fc
      })

    } catch(e){
      console.log(`Error converting text to feature collection: ${e}`)

      setFeatureLibrary(prev=> {
        let fc = structuredClone(prev)
        fc[Object.keys(featureLibrary)[selectedIndex]] = event.target.textContext
        return fc
      })
    }



  }

  useEffect(()=>{
    if (isEditGeography){
      try {
        const editLayer = new EditableGeoJsonLayer({
          id: "geojson-layer",
          data: features,
          pickable:true, 
          autoHighlight:true,
          onClick: (info)=>{
            setSelectedFeIndex(info.index)
          },
          mode,
          selectedFeatureIndexes,
          onEdit: ({ updatedData }) => {
            if(updatedData.features.length >0 ){
              setFeatures((prev) => updatedData);
            }
          },
        });

        console.dir(features)

        setLayers((prev)=> [...prev.filter(d =>  d.id != 'geojson-layer'), editLayer])
      } catch (e) {
        console.log(`Error Generating EditableGeoJSONLayer:  ${e}`)
      }
      } else {
        console.log("Remove Layer")
        setLayers((prev)=> [...prev.filter(d =>  d.id != 'geojson-layer')])
      }
    
  }, [features, isEditGeography, selectedFeIndex, mode])

 

  if (selectedIndex >= 0 && managePolygons) {
    selectedFeatureCollectionText = Object.values(featureLibrary)[selectedIndex]

    try {
      selectedFeatureCollectionText = new GeoJSON().readFeatures(selectedFeatureCollectionText)

      
      switch(featureFormat) {
        case "feature-collection":
          selectedFeatureCollectionText = new GeoJSON().writeFeatures(selectedFeatureCollectionText)
          break;
        case "WKT":
          selectedFeatureCollectionText = new WKT().writeFeatures(selectedFeatureCollectionText)
          break;
        case "geojson":
          selectedFeatureCollectionText = selectedFeatureCollectionText.map(d=> new GeoJSON().writeGeometry(d.getGeometry())).join('\n')
          break;
        case "kml":
          selectedFeatureCollectionText = new KML().writeFeatures(selectedFeatureCollectionText)
          break;
      }

    } catch (e){
      console.log(`Error Converting Feature Collection: ${e}`)
    }
  }

  
  

  return (
    <React.Fragment>
    <IconButton
    size="large"
    edge="end"
    color="inherit"
    aria-label="menu"
    sx={{ mr: 2}}
    onClick={handleClick}
  >
    <HighlightAltIcon />
  </IconButton>
  <Menu
      id="basic-menu"
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={()=> {handleClose(); setManagePolyons(prev => !prev)}}>Manage Polygons</MenuItem>
      <MenuItem onClick={handleClick2}>Edit </MenuItem>
      <MenuItem onClick={()=>{handleClose(); setMode(()=>ViewMode); setIsEditGeography(true)}}>View</MenuItem>
      <MenuItem onClick={handleSetFeatureLibrary}>Save Feature Collection</MenuItem>
      <MenuItem onClick={()=>{handleClose(); setMode(()=>ViewMode); setIsEditGeography(false); setFeatures({"type":"FeatureCollection","features":[]}); setFeatureCollectionName('Untitled Feature Collection')}}>Exit</MenuItem>
   </Menu>
   <Menu
      id="basic-menu"
      anchorEl={anchorEl2}
      open={open2}
      onClose={handleClose2}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
    >
      <MenuItem onClick={()=> {handleClose2(); setMode(()=>DrawRectangleMode); setIsEditGeography(true)}}>Draw Rectangle</MenuItem>
      <MenuItem onClick={()=> {handleClose2(); setMode(()=>DrawPolygonMode); setIsEditGeography(true)}}>Draw Polygon</MenuItem>
      <MenuItem onClick={()=> {handleClose2(); setMode(()=>ModifyMode); setIsEditGeography(true)}}>Modify Polygon</MenuItem>
      <MenuItem onClick={()=> {handleClose2(); setMode(()=>ExtrudeMode); setIsEditGeography(true)}}>Extrude Mode </MenuItem>
      <MenuItem onClick={()=> {handleClose2(); setMode(()=>ScaleMode); setIsEditGeography(true)}}>Scale Mode</MenuItem>
      <MenuItem onClick={()=> {handleClose2(); handleDeleteSelected()}}>Delete Selected</MenuItem>
   </Menu>
   {cardPortal && isEditGeography &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
            <Stack spacing={2} >
              <FormGroup>
                  <FormControl variant="outlined">
                      <InputLabel>Feature Collection Name</InputLabel>
                      <OutlinedInput value={featureCollectionName}
                          onChange={()=> setFeatureCollectionName(event.target.value)}
                      />
                  </FormControl>
                </FormGroup>
              </Stack>
            </CardContent>
            </Card>
          </Portal> 
    }
   {cardPortal && managePolygons &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 750}}>
            <CardContent>
            <Stack direction="row">
              <Stack>
                <List sx={{minWidth:250}}>
          {Object.keys(featureLibrary).map((d,i)=> 
                    <ListItem key={i}
                    selected={selectedIndex === i}
                    onClick={(event)=> handleListItemClick(event, i)}
                      secondaryAction={
                        <IconButton edge="end" aria-label="delete" onClick={handleDeleteFeatureCollection}>
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText
                        primary={d}
                      />
                    </ListItem>)}

                </List>
            </Stack>
            <Stack spacing={2}>
            <ToggleButtonGroup sx={{minWidth: 500}}
                value={featureFormat}
                exclusive
                onChange={handleSetFeatureFormat}
              >
                <ToggleButton value="feature-collection" aria-label="left aligned" sx={{flexGrow:1}}>
                  Feature Collection
                </ToggleButton>
                <ToggleButton value="WKT" aria-label="centered" sx={{flexGrow:1}}>
                  WKT
                </ToggleButton>
                <ToggleButton value="geojson" aria-label="right aligned" sx={{flexGrow:1}}>
                  GeoJSON
                </ToggleButton>
                <ToggleButton value="kml" aria-label="justified" sx={{flexGrow:1}}>
                  KML
                </ToggleButton>
              </ToggleButtonGroup>

            <TextField sx={{minWidth: 500}}
                id="geojson"
                label="GeoJSON"
                multiline
                rows={20}
                value={selectedFeatureCollectionText}
                onChange={handleTextFieldChange}
              />
            </Stack>
           </Stack>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button sx={{flexGrow:1}} >Login</Button>
            </CardActions>
            </Card>
          </Portal> 
    }
   </React.Fragment>
  )
}



export {GeometryEditor}