import { get, set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { indexedDBEffect } from '../GlobalState';

/*
const UrsaspaceAPIToken = atom({
    key: 'pageInfo',
    default: {count:0, snackOpen: false, isLoading: false, recordCount: 0},
  });
  */

  let end = new Date().toISOString()
  let start = new Date(new Date().setDate(new Date().getDate()-5)).toISOString()
  
  const UrsaspaceAPIToken = atom({
    key: 'UrsaspaceAPIToken',
    default: null,
    effects: [
      indexedDBEffect('ursaspaceAPIToken')
    ]
  });

  const DetectionStart = atom({
    key: 'DetectionStart',
    default: start,
  });

  const DetectionEnd = atom({
    key: 'DetectionEnd',
    default: end,
  });


  export {UrsaspaceAPIToken, DetectionStart, DetectionEnd}