import {PathLayer} from '@deck.gl/layers';


const getMaritimePredictedRouteLayer = ({maritimePredictedRouteData, setClickInfo})=>{

    const layer = new PathLayer({
        id: 'maritime-predicted-route-layer',
        data: maritimePredictedRouteData,
        pickable: true,
        widthScale: 20,
        widthMinPixels: 2,
          onClick: (info, event)=>{
            let record = structuredClone(info.object)
            setClickInfo((prev)=> [...prev.filter( d=>d.staticData.mmsi != record.staticData.mmsi), record]);
        },
        getPath: d => d.currentVoyage.predictedRoute.waypoints.geoJson.coordinates,
        getColor: d => [255, 0, 0],
        getWidth: d => 5
    });

    return layer
}

export {getMaritimePredictedRouteLayer}