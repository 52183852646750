let defaultDate = new Date(new Date().setDate(new Date().getDate()-30)).toISOString()



let getDGVEvents = async ({mmsis, setDGVEventData, dgvAPIToken, startDate=defaultDate}) => {
    //let nextUrl = `dgv/events?dtg_start=${startDate}&position_bbox=-92,-182,92,182&`
    let nextUrl = `gws/ows?service=WFS&version=1.1.0&request=GetFeature&outputformat=json&typenames=exactAIS:RFGL-EVENT`
    
    if (typeof(mmsis) != 'undefined'){
        nextUrl = nextUrl+`&cql_filter=mmsi in (${mmsis})`
    }
    

    
    try{
        let response = await fetch(nextUrl+`&authKey=${dgvAPIToken}`,
            /*{
            headers:{'x-api-key': dgvAPIToken}
            }*/);

        let data = await response.json()
        
        setDGVEventData(prev => {
            let priorData = structuredClone(prev);
            let newData = data.features.map(d => d.properties)
            return [...priorData, ...newData];
        });
        
        
        if(data.links?.length > 0 ){
            for (link of data.links) {
                if (link.nextPageUrl) {
                    let nextUrl = link.nextPageUrl.split('/').slice(3).join('/')
                    getDGVEventData({dgvAPIToken, setDGVEventData, nextUrl})
                }
            }
        }

        
    } catch (e) {
        console.log(`Error fetching DGV Event Data: ${e}`)
    }
}




export {getDGVEvents}