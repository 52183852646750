{
    "version": 8,
    "sources": {
      "openseamap": {
        "type": "raster",
        "tiles": [
          "https://server.arcgisonline.com/ArcGIS/rest/services/Canvas/World_Light_Gray_Base/MapServer/tile/{z}/{y}/{x}"
        ],
        "tileSize": 256
      }
    },
    "layers": [
      {
        "id": "openseamaplayer",
        "source": "openseamap",
        "type": "raster"
      }
    ]
  }