import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import { DeckLayers, HoverInfo, SE_APItoken } from '../GlobalState';
import {BitmapLayer} from '@deck.gl/layers';
import {TileLayer} from '@deck.gl/geo-layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import chroma from 'chroma-js'
import {CSVLoader} from '@loaders.gl/csv';
import {parse, fetchFile} from '@loaders.gl/core';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';



let DensityTileLayer = ()=>{
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayDensityLayerOptions, setDisplayDensityLayerOptions] = useState(false)
    const setHoverInfo = useSetRecoilState(HoverInfo)
    const [densityLayerView, setDensityLayerView] = useState(false)
    const [source, setSource] = useState("/app/ais.csv")
    const [sE_APItoken, setsE_APItoken] = useRecoilState(SE_APItoken)


    const handleChange = (event) => {
        setSource(event.target.value);
      };


    useEffect(()=>{
              if(densityLayerView){
                const newlayer = 
                    new TileLayer({id: 'vessel-density-layer', 
                                data: '/se/ais_tiles/{z}/{x}/{y}.png',
                                minZoom: 0,
                                maxZoom: 19,
                                tileSize: 256,
                                loadOptions:{
                                  fetch:{
                                      headers:{
                                        'X-Api-Key':sE_APItoken
                                     }
                                    }
                                  }
                                ,
                                renderSubLayers: props => {
                                const {
                                    bbox: {west, south, east, north}
                                } = props.tile;

                                return new BitmapLayer(props, {
                                    data: null,
                                    image: props.data,
                                    bounds: [west, south, east, north]
                                });
                                }
                            });

                setLayers((prev)=> [...prev.filter(d => d.id != 'vessel-density-layer'), newlayer]);
                } else {
                setLayers((prev) => {
                  prev = prev.filter(d => d.id != 'vessel-density-layer')
                  return prev
                });
            }
    },[densityLayerView])

    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={()=> setDisplayDensityLayerOptions(prev => !prev)}>Vessel Density</Button>
              {cardPortal && displayDensityLayerOptions ? <Portal container={cardPortal.current}>
              <Card><CardContent>
                <Stack spacing={2}>
                  
                <FormGroup>
                {/*<FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Source</InputLabel>
                    <Select
                    value={source}
                    label="Source"
                    onChange={handleChange}
                    >
                    <MenuItem value={"/app/ais.csv"}>AIS</MenuItem>
                    <MenuItem value={"/app/adsb.csv"}>ADS-B</MenuItem>
                    <MenuItem value={"/app/combined.csv"}>Combined</MenuItem>
                    </Select>
                </FormControl>*/}
                <FormControlLabel control={
                  <Switch
                    checked={densityLayerView}
                    onChange={()=> setDensityLayerView(prev => !prev)}
                    color='info'
                    
                  />
                } label="Density Layer" />
                </FormGroup>
                </Stack>
            </CardContent></Card>
            </Portal> : '' }
        </React.Fragment>)
     }
   
     export {DensityTileLayer}