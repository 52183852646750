import React from 'react';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import {RecoilRoot} from 'recoil';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import theme from './theme';
import { App } from './App';


  

export function Main() {

    return (
    <React.StrictMode>
        <RecoilRoot>
          <ThemeProvider theme={theme}>
            <CssBaseline/>
              <App/>
          </ThemeProvider>
        </RecoilRoot>
      </React.StrictMode>
    )
  }