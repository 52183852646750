
import React, { useState, useEffect, useCallback } from 'react';
import {atom, selector, useRecoilState} from 'recoil';

import {
  COORDINATE_SYSTEM,
  _GlobeView as GlobeView,
  LightingEffect,
  AmbientLight,
  _SunLight as SunLight
} from '@deck.gl/core';

import DemoAppBar from './DemoAppBar'
import DeckGL from '@deck.gl/react';
import {ScatterplotLayer, LineLayer} from '@deck.gl/layers';
import 'maplibre-gl/dist/maplibre-gl.css';
import {Map, AttributionControl} from 'react-map-gl';
import maplibregl, { SourceFeatureState } from 'maplibre-gl';
import MapStyle from './style.json'
import { Calculate } from '@mui/icons-material';
import DemoBottomBar from './DemoBottomBar';
import {PageInfo, MaritimeData, StopMaritimeData, MaritimeLayer, ClickInfo} from './Maritime/MaritimeState'
import {CardPortal} from './GlobalState'
import Portal from '@mui/base/Portal';
import Stack from '@mui/material/Stack';
import { DeckLayers, HoverInfo, ViewState } from './GlobalState';
import { ContextMenu } from './ContextMenu';




  

  
  // DeckGL react component
  export function App() {
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [viewState, setViewState] = useRecoilState(ViewState)
    const portalRef = React.useRef(null)
    const [selectedFeatureIndexes] = React.useState([]);
    const [contextMenu, setContextMenu] = React.useState(null)
    const [coordinates, setCoordinates] = React.useState(null)
    
    useEffect(()=>{
      setCardPortal(portalRef);
    },[portalRef])

    let handleContextMenu = (info, event)=> {
      if(event.rightButton){
        setContextMenu({info, event})
        console.dir({info,event})
      } else {
        setContextMenu(null)
      }
    }

    let handleOnHover = (info,event) => {
      setCoordinates(info.coordinate)
    }

    return (
      <React.Fragment>
            <DemoAppBar />
            <DeckGL
            //views={new GlobeView()}
            initialViewState={viewState}
            controller={true}
            layers={layers}
            pickingRadius={10}
            style={{height: `calc(100% - 96px)`, top:'48px'}}
            onClick={handleContextMenu}
            onHover={handleOnHover}
            >
                <Map mapLib={maplibregl} mapStyle={MapStyle}/>
                {hoverInfo.object && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: hoverInfo.y}}>
                    { hoverInfo.object?.staticData?.name || hoverInfo.object?.vessel_name}
                </div> )}
                {hoverInfo.object && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: hoverInfo.y}}>
                    { hoverInfo.object?.message}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'dgv-event-layer-reported' || hoverInfo.layer.id == 'dgv-event-layer-estimated')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.layer.id == 'dgv-event-layer-reported' ? 'Reported' : 'Estimated'}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'weather-route-layer')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.object.times.valid_time}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'weather-insights-layer')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.object.start.time}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'port-index-layer')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.object["Main Port Name"]}
                </div> )}
                {hoverInfo.object && (hoverInfo.layer.id == 'aviation-layer')  && (
                  <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: hoverInfo.x, top: (hoverInfo.y + 13)}}>
                    { hoverInfo.object["icao_address"]} 
                </div> )}
                {contextMenu  && 
                ( <div style={{position: 'absolute', zIndex: 1, left: contextMenu.info.x, top: contextMenu.info.y}} id="contextMenu">
                  <ContextMenu contextMenu={contextMenu} setContextMenu={setContextMenu} onContextMenu={handleContextMenu}/>
                  </div> )}
                {coordinates  && 
                ( <div style={{position: 'absolute', zIndex: 1, pointerEvents: 'none', left: `calc(100% - 170px)`, top: `calc(100% - 28px)`}}>
                  {`${Math.abs(Math.round(1000*coordinates[1])/1000)}°${coordinates[1] > 0 ? 'N' : 'S'},  ${Math.abs(Math.round(1000*coordinates[0])/1000)}°${coordinates[0] > 0 ? 'E' : 'W'}`}
                  </div> )}



                  
            </DeckGL>
            <DemoBottomBar/>
            <Stack ref={portalRef} sx={{position:'absolute', margin:'30px', zIndex:'10', maxHeight: `calc(100% - 96px)`, flexWrap:'wrap', gap: 2}}/>
      </React.Fragment>
    )
  }