import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';
import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {MaritimeGWSClickInfo, MaritimeGWSStyles} from './MaritimeGWSState.js'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { MaritimeAPIToken, ViewState, DeckLayers, DGVAPIToken, isDataLoading } from '../GlobalState';
import { MaritimeGWSLayers } from './MaritimeGWSState.js';
import { MaritimeGWSCQLFilter, MaritimeGWSCapabilities } from './MaritimeGWSState.js';


//maritimeGWSCapabilities.Capability.Layer.Layer

let getPastDate = (start, interval) => {
  let startDate =  new Date(new Date(start).setDate(new Date(start).getDate()-interval))
  startDate = startDate.getFullYear() + ("0"+(startDate.getMonth()+1)).slice(-2) + ("0" + startDate.getDate()).slice(-2) + ("0" + startDate.getHours()).slice(-2) + ("0" + startDate.getMinutes()).slice(-2) + ("0" + startDate.getSeconds()).slice(-2);
  return startDate
}

let MaritimeGWSCard = ()=> {
    const [maritimeGWSClickInfo, setMaritimeGWSClickInfo] = useRecoilState(MaritimeGWSClickInfo);
    const [tabValue, setTabValue] = React.useState(0);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const setIsDataLoading = useSetRecoilState(isDataLoading)
    const [cqlfilter, setCQLFilter] = useRecoilState(MaritimeGWSCQLFilter);
    const [maritimeGWSLayers,setMaritimeGWSLayers] = useRecoilState(MaritimeGWSLayers);
    const [maritimeGWSStyles, setMaritimeGWSStyles] = useRecoilState(MaritimeGWSStyles)
    const maritimeGWSCapabilities = useRecoilValue(MaritimeGWSCapabilities)
    
    let handleChange = (event,  newValue)=>{
      setTabValue(newValue)
    }

    let filterByVessel = ()=> {
      setCQLFilter(()=> `mmsi=${maritimeGWSClickInfo[tabValue].mmsi}`)
    }

    let clearFilters = () => {
        setCQLFilter('')
        setMaritimeGWSLayers([maritimeGWSCapabilities.Capability.Layer.Layer[2]])
        setMaritimeGWSStyles(prev => {
          let value = Object.values(prev)
          console.log(prev)
          let newStyle = {}
          newStyle[maritimeGWSCapabilities.Capability.Layer.Layer[2].Name] = value
          console.log(newStyle)
          return newStyle
        })

    }


    let getHistoricalPositions = ()=> {
      setCQLFilter(() => `mmsi=${maritimeGWSClickInfo[tabValue].mmsi} AND start=${(getPastDate(maritimeGWSClickInfo[tabValue].dt_pos_utc, 30))} AND end=${maritimeGWSClickInfo[tabValue].ts_pos_utc}`)
      setMaritimeGWSLayers([maritimeGWSCapabilities.Capability.Layer.Layer[0]])
      setMaritimeGWSStyles(prev => {
        let value = Object.values(prev)
        console.log(prev)
        let newStyle = {}
        newStyle[maritimeGWSCapabilities.Capability.Layer.Layer[0].Name] = value
        console.log(newStyle)
        return newStyle
      })
    }

    let data = maritimeGWSClickInfo


    return (
        <Card sx={{overflow:'hidden', maxWidth:500}}>
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile sx={{maxWidth:500}}>
            {data.map((d, i) => <Tab label={d.vessel_name} value={i} key={i} /> )}
          </Tabs>
          </Box>
          {data.map((d,i)=> (
          <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
          <TableContainer sx={{ minWidth: 250, maxHeight:550}}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell colSpan={2} align='center'>MMSI: {d['mmsi']} {d['imo'] ? "/ IMO:" + d['imo'] : null}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Feature</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(d).map(([feature,value]) => 
              (
                <TableRow
                  key={feature}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {feature}
                  </TableCell>
                  <TableCell align="right">{typeof(value) != 'object' ? value : JSON.stringify(value) }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box> ))}
        </CardContent>
        <CardActions sx={{flexDirection:'column'}}>
          <Button onClick={filterByVessel}>Filter By Vessel</Button>
          <Button onClick={getHistoricalPositions}>Get Historical Positions</Button>
          <Button onClick={clearFilters}>Clear Filters</Button>
          <Button onClick={()=> setMaritimeGWSClickInfo([])}>Close</Button>
        </CardActions>
        </Card>
      )
}

export {MaritimeGWSCard}