import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {WeatherAPIToken} from '../GlobalState'
import { ForecastWeatherConditions } from './WeatherState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { DeckLayers } from '../GlobalState';
import {ScatterplotLayer} from '@deck.gl/layers';


let ForecastWeatherConditionsDisplay = ()=>{
    const [forecastWeatherConditions, setForecastWeatherConditions] = useRecoilState(ForecastWeatherConditions)
    const [layers,setLayers] = useRecoilState(DeckLayers)

    useEffect(()=> {
        if(forecastWeatherConditions) {
              console.log(forecastWeatherConditions)

              const newlayer = 
              new ScatterplotLayer({id: 'weather-forecast-layer', 
                          data: forecastWeatherConditions.data,
                          pickable: true,
                          stroked: true,
                          filled: true,
                          radiusScale: 6,
                          radiusMinPixels: 1,
                          radiusMaxPixels: 100,
                          lineWidthMinPixels: 1,
                          getFillColor: d => [0,0,0],
                          getLineColor: d => [0,0,0],
                          getPosition: value => [value.location.coordinates.lon, value.location.coordinates.lat], 
                        })
            ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'weather-forecast-layer'), newlayer]);
          } else {
            setLayers((prev) => {
              return prev.filter(d => d.id != 'weather-forecast-layer')
            });
          }
      }
    , [forecastWeatherConditions]);

    
    if (forecastWeatherConditions) {
        let {meta,data} = forecastWeatherConditions
        features = Object.keys(data[0].values)
        valid_times = data.map(d=> d.times.valid_time)

        return (
            <Card sx={{overflow:'hidden', maxWidth:1500}}>
                <CardContent>
                <TableContainer sx={{ minWidth: 950, maxHeight:550}}>
                <Table stickyHeader>
                    <TableHead>
                    <TableRow>
                        <TableCell colSpan={features.length} align='left'>{meta.forecast}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell></TableCell>
                        {valid_times.map(time => <TableCell align="right"> {(new Date(time)).toUTCString()} </TableCell>)}
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {features.map((feature) => (
                        <TableRow
                        key={feature}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                        <TableCell component="th" scope="row" style={{position:'sticky',left:0, background:'white'}}>
                            {feature}
                        </TableCell>
                        {data.map(d => d.values[feature]).map((value, i) => <TableCell key={i+feature+value} align="right">{value}</TableCell>)}
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
                </TableContainer>
                </CardContent>
                <CardActions sx={{flexDirection:'column'}}>
                    <Button onClick={()=> setForecastWeatherConditions(null)}>Close</Button>
                </CardActions>
            </Card>
          )
    } 
}

export {ForecastWeatherConditionsDisplay}