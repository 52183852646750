import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {WeatherAPIToken} from '../GlobalState'
import { CurrentWeatherConditions } from './WeatherState';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { DeckLayers } from '../GlobalState';
import {ScatterplotLayer} from '@deck.gl/layers';


let CurrentWeatherConditionsDisplay = ()=>{
    const [currentWeatherConditions, setCurrentWeatherConditions] = useRecoilState(CurrentWeatherConditions)
    const [layers,setLayers] = useRecoilState(DeckLayers)

    useEffect(()=> {
        if(currentWeatherConditions) {
              const newlayer = 
              new ScatterplotLayer({id: 'weather-current-layer', 
                          data: currentWeatherConditions.data,
                          pickable: true,
                          stroked: true,
                          filled: true,
                          radiusScale: 6,
                          radiusMinPixels: 1,
                          radiusMaxPixels: 100,
                          lineWidthMinPixels: 1,
                          getFillColor: d => [0,0,0],
                          getLineColor: d => [0,0,0],
                          getPosition: value => [value.location.coordinates.lon, value.location.coordinates.lat], 
                        })
            ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'weather-current-layer'), newlayer]);
          } else {
            setLayers((prev) => {
              return prev.filter(d => d.id != 'weather-current-layer')
            });
          }
      }
    , [currentWeatherConditions]);

    
    if (currentWeatherConditions) {
    let {meta,data} = currentWeatherConditions
    data = data[0]



    return (
        <Card sx={{overflow:'hidden', maxWidth:500}}>
            <CardContent>
            <TableContainer sx={{ minWidth: 250, maxHeight:550}}>
            <Table stickyHeader>
                <TableHead>
                <TableRow>
                    <TableCell colSpan={2} align='center'>{meta.forecast}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={2} align='center'>Issued: {data.times.issuance_time} <br/>Valid: {data.times.valid_time}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Feature</TableCell>
                    <TableCell align="right">Value</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {Object.entries(data.values).map(([feature,value]) => (
                    <TableRow
                    key={feature}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                    <TableCell component="th" scope="row">
                        {feature}
                    </TableCell>
                    <TableCell align="right">{Math.round(value*1000)/1000}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button onClick={()=> setCurrentWeatherConditions(null)}>Close</Button>
            </CardActions>
        </Card>
      )
    } 
}

export {CurrentWeatherConditionsDisplay}