import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';

import React, { useState, useEffect } from 'react';
import {PageInfo, ClickInfo} from '../Maritime/MaritimeState'
import {CardPortal, DeckLayers, HoverInfo} from '../GlobalState'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import Portal from '@mui/base/Portal';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
//import vessel from 'data-url:./vessel.svg'
import chroma from 'chroma-js'
import {IconLayer} from '@deck.gl/layers'
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import Button from '@mui/material/Button';

import { DetectionEnd, DetectionStart, UrsaspaceAPIToken } from './UrsaSpaceState';
import { getUrsaSpaceQuery } from './UrspaceDefaultQuery';
import {GeoJsonLayer, IconLayer} from '@deck.gl/layers';
import { DetectionCard } from './DetectionCard';
import { isDataLoading } from '../GlobalState';
import vessel from 'data-url:../Maritime/vessel.svg'
import { Stack } from '@mui/system';

const ICON_MAPPING = {
  vessel: {x:0, y:0, width:12, height:24, mask:true}
}


  let getDetectionLayer= ({data, setDetectionInfo})=> {
    let detectionLayer = new IconLayer({
        id: 'ursa-vessel-dections-layer',
        data,
        pickable: true,
        iconAtlas: vessel,
        iconMapping: ICON_MAPPING,
        sizeMaxPixels: 10,
        sizeMinPixels: 8, 
        sizeScale:8,
        getIcon: d => 'vessel',
        getPosition: d => [d.properties.sarLongitude, d.properties.sarLatitude, 10],
        getAngle: d =>  360- d.properties.sarHeading || 0,
        getColor: d => d.properties.aisMmsi ? [0,0,255] : [255,0,0],
        onClick: d => {setDetectionInfo([d.object.properties])}
      });

    return detectionLayer
  }

  let end = new Date().toISOString()
  let start = new Date(new Date().setHours(new Date().getHours()-24)).toISOString()

  let UrsaspaceDataComponent= ()=>{
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [displayUrsaspaceOptions, setDisplayUrsaspaceOptions] = useState(false)
    const [ursaspaceAPIToken, setUrsaspaceAPIToken] = useRecoilState(UrsaspaceAPIToken)
    const [vesselDetections, setVesselDetections] = useState([])
    const [detectionInfo, setDetectionInfo] = useState(null)
    const setIsDataLoading = useSetRecoilState(isDataLoading)
    const username= React.useRef('un')
    const password = React.useRef('pw')
    const apiKey = React.useRef('apiKey')
    const [startText, setStartText] = useRecoilState(DetectionStart)
    const [endText, setEndText] = useRecoilState(DetectionEnd)

    let handleAddAPIKey = () => {
      setUrsaspaceAPIToken(apiKey.current.value)
    }
    
    let handleLogin = async ()=> {

        let body = new URLSearchParams()
        body.set('grant_type', "password")
        body.set('username',username.current.value)
        body.set('password',password.current.value)

        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type":"application/x-www-form-urlencoded"
            },
            body,
            redirect: 'follow',
            mode: 'cors',
          };
          setIsDataLoading(true)
        try {
          let request = await fetch("api/pas/login", requestOptions)

          if (!request.ok){
            console.dir(request)
            let err = new Error(request.text)
            throw err
          }

          let response = await request.json()
        
          setUrsaspaceAPIToken(response.access_token)
          setIsDataLoading(false)
        } catch (e){
          setIsDataLoading(false)
          console.log(e)
        }


        }

    let handleVesselDetections = async ()=>{
        let dates = {start: startText, end: endText}

        let query = getUrsaSpaceQuery(dates)
        let requestOptions = {
            method: 'POST',
            headers: {
                "Content-Type":"application/json",
                "Authorization":`Bearer ${ursaspaceAPIToken}` 
            },
            body:query,
            redirect: 'follow',
            mode: 'cors',
          };
        
        setIsDataLoading(true)
        try {
        let request = await fetch("api/psdm/graphql/gmss", requestOptions)
        
        if (!request.ok){
          if (request.status == 401){
            setUrsaspaceAPIToken(null)
          }else {
            console.dir(request)
            let err = new Error(await request.text())
            throw err
          }
        }

        let response = await request.json()
        
        let records = response.data.ursa_udp_analyticgeojsonrecord.map(d=> d.content.features.map(feature => {feature.properties.region=d.content.processors[0].parameters.dissemination.region; return feature})).flat()
        setVesselDetections(records)
        setIsDataLoading(false)

        } catch (e){
          setIsDataLoading(false)
          console.log(e)
        }

    }



    useEffect(()=>{
        if(vesselDetections.length > 0 ){
            
            setLayers((prev)=> [...prev.filter(d => d.id != 'ursa-vessel-dections-layer'), getDetectionLayer({data:vesselDetections, setDetectionInfo})]);
        } else {
            setLayers((prev) => prev.filter(d => d.id != 'ursa-vessel-dections-layer')); 
        }
    }, [vesselDetections])


   return (
     <React.Fragment>
        <Button variant="text" color="secondary" onClick={()=> setDisplayUrsaspaceOptions(prev => !prev)}>Ursa Space GMS</Button>
        {cardPortal && displayUrsaspaceOptions && !ursaspaceAPIToken &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
            <Stack spacing={2} >
                <FormGroup>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="urasspaceUser">User Name</InputLabel>
                    <OutlinedInput inputRef={username}
                        id="ursaSpaceUsername"
                    />
                </FormControl>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="ursaspacePassword">Password</InputLabel>
                    <OutlinedInput inputRef={password}
                        id="ursaspacePassword"
                        type='password'
                        label="Password"
                    />
                </FormControl>
                </FormGroup>
              </Stack>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button sx={{flexGrow:1}} onClick={handleLogin}>Login</Button>
            </CardActions>
            </Card>
          </Portal> 
          }
          {cardPortal && displayUrsaspaceOptions && !ursaspaceAPIToken &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
            <Stack spacing={2} >
                <FormGroup>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="urasspaceUser">API Key</InputLabel>
                    <OutlinedInput inputRef={apiKey}
                        id="ursaSpaceapiKey"
                    />
                </FormControl>
                </FormGroup>
              </Stack>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button sx={{flexGrow:1}} onClick={handleAddAPIKey}>Login</Button>
            </CardActions>
            </Card>
          </Portal> 
          }
        {cardPortal && displayUrsaspaceOptions && ursaspaceAPIToken &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300}}>
            <CardContent>
              <Stack spacing={3}>
              <FormControl variant="outlined">
                    <InputLabel htmlFor="start">Start Date</InputLabel>
                    <OutlinedInput 
                        id="start"
                        onChange={(event)=> setStartText(event.target.value)}
                        value={startText}
                    />
                </FormControl>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="end">End Date</InputLabel>
                    <OutlinedInput 
                        id="end"
                        onChange={(event)=> setEndText(event.target.value)}
                        value={endText}
                    />
                </FormControl>
                </Stack>
            </CardContent>
            <CardActions sx={{flexDirection:'column'}}>
                <Button sx={{flexGrow:1}} onClick={handleVesselDetections}>{vesselDetections.length == 0 ? "Get Vessel Detections" : "Update Vessel Detections"}</Button>
                {vesselDetections.length > 0 && <Button sx={{flexGrow:1}} onClick={()=> setVesselDetections([])}>Clear Vessel Detections</Button>}
            </CardActions>
            </Card>
          </Portal> 
          }
          
          {cardPortal && detectionInfo && <Portal container={cardPortal.current}>
            <DetectionCard data={detectionInfo} setDetectionInfo={setDetectionInfo} setVesselDetections={setVesselDetections}/>
        </Portal>}
          </React.Fragment>)
  } 

  export {UrsaspaceDataComponent} 