import * as React from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';

import IconButton from '@mui/material/IconButton';
import Input from '@mui/material/Input';
import FilledInput from '@mui/material/FilledInput';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Switch from '@mui/material/Switch';

import {AviationAPIToken, MaritimeAPIToken, DGVAPIToken, WeatherAPIToken, MaritimeGWSAPIToken, FeatureVesselToPortETA, FeatureHistoricalPositions, FeatureEnhancedVC, FeatureURSASPACE, FeatureGNSSIndicators, FeatureSpireSatellites, SE_APItoken, FeatureVesselDensity} from './GlobalState'
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';


let Settings = () => {
    const [showPassword, setShowPassword] = React.useState(false);
    const [maritimeAPIToken, setMaritimeAPIToken] = useRecoilState(MaritimeAPIToken);
    const [aviationAPIToken, setAviationAPIToken] = useRecoilState(AviationAPIToken);
    const [dgvAPIToken, setDGVAPIToken] = useRecoilState(DGVAPIToken);
    const [weatherAPIToken, setWeatherAPIToken] = useRecoilState(WeatherAPIToken)
    const [maritimeGWSAPIToken, setMaritimeGWSAPIToken] = useRecoilState(MaritimeGWSAPIToken)
    const [sE_APItoken, setsE_APItoken] = useRecoilState(SE_APItoken)

    //Features
    const [featureVesselToPortEta, setFeatureVesselToPortETA] = useRecoilState(FeatureVesselToPortETA)
    const [featureHistoricalPositions, setFeatureHistoricalPositions] = useRecoilState(FeatureHistoricalPositions)
    const [featureEnhancedVC, setFeatureEnhancedVC] = useRecoilState(FeatureEnhancedVC)
    const [featureUrsa, setFeatureUrsa] = useRecoilState(FeatureURSASPACE)
    const [featureGNSSIndicators, setFeatureGNSSIndicators] = useRecoilState(FeatureGNSSIndicators)
    const [featureSpireSatellites, setFeatureSpireSatellites] = useRecoilState(FeatureSpireSatellites)
   
    const [featureVesselDensity, setFeatureVesselDensity] = useRecoilState(FeatureVesselDensity)



    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event) => {
      event.preventDefault();
    };

    const handleAviationAPIChange = (event) => {
        setAviationAPIToken(event.target.value);
      };

    const handleMaritimeAPIChange = (event) => {
        setMaritimeAPIToken(event.target.value);
      };

    const handleDGVAPIChange = (event) => {
      setDGVAPIToken(event.target.value);
    };

    const handleWeatherAPIChange = (event) => {
      setWeatherAPIToken(event.target.value);
    };

    const handleMaritimeGWSAPIChange = (event) => {
      setMaritimeGWSAPIToken(event.target.value);
    };

    const handleSEAddonsChange = (event) => {
      setsE_APItoken(event.target.value);
    };


    return (
    <Card sx={{minWidth:450}}>
      <CardContent>
        To enable demo functionality, please enter your API tokens below. 
        <pre>
          
        </pre>
      <Stack spacing={2}>
      <FormControl variant="outlined">
          <InputLabel htmlFor="maritimetoken">Maritime 2.0</InputLabel>
          <OutlinedInput
            id="maritimetoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleMaritimeAPIChange}
            value={maritimeAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
          {maritimeAPIToken &&
          <Stack>
        <FormGroup sx={{flexDirection:'row'}}>
                <FormControlLabel control={
                  <Switch
                    checked={featureVesselToPortEta}
                    onChange={()=> setFeatureVesselToPortETA(prev => !prev)}
                    color='info'
                    
                  />
                } label="Vessel to Port ETA" />
                <FormControlLabel control={
                  <Switch
                    checked={featureHistoricalPositions}
                    onChange={()=> setFeatureHistoricalPositions(prev => !prev)}
                    color='info'
                    
                  />
                } label="Historical Positions" />
                <FormControlLabel control={
                  <Switch
                    checked={featureEnhancedVC}
                    onChange={()=> setFeatureEnhancedVC(prev => !prev)}
                    color='info'
                    
                  />
                } label="Enhanced Vessel Characteristics" />
          </FormGroup>
          </Stack>}
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="maritimeGWStoken">Maritime GWS</InputLabel>
          <OutlinedInput
            id="maritimegwstoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleMaritimeGWSAPIChange}
            value={maritimeGWSAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="aviationtoken">Aviation</InputLabel>
          <OutlinedInput
            id="aviationtoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleAviationAPIChange}
            value={aviationAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="dgvtoken">DGV</InputLabel>
          <OutlinedInput
            id="dgvtoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleDGVAPIChange}
            value={dgvAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="weathertoken">Weather</InputLabel>
          <OutlinedInput
            id="weathertoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleWeatherAPIChange}
            value={weatherAPIToken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        <FormControl variant="outlined">
          <InputLabel htmlFor="setoken">SE Add-ons</InputLabel>
          <OutlinedInput
            id="setoken"
            type={showPassword ? 'text' : 'password'}
            onChange={handleSEAddonsChange}
            value={sE_APItoken}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>
        {sE_APItoken != '' ? <FormGroup sx={{flexDirection:'row'}}>
                <FormControlLabel control={
                  <Switch
                    checked={featureVesselDensity}
                    onChange={()=> setFeatureVesselDensity(prev => !prev)}
                    color='info'
                    
                  />
                } label="Vessel Density" />
                <FormControlLabel control={
                  <Switch
                    checked={featureGNSSIndicators}
                    onChange={()=> setFeatureGNSSIndicators(prev => !prev)}
                    color='info'
                    
                  />
                } label="GNSS Indicators" />
                <FormControlLabel control={
                  <Switch
                    checked={featureSpireSatellites}
                    onChange={()=> setFeatureSpireSatellites(prev => !prev)}
                    color='info'
                    
                  />
                } label="Spire Satellite View" />
        </FormGroup>: ''}
                <FormControlLabel control={
                  <Switch
                    checked={featureUrsa}
                    onChange={()=> setFeatureUrsa(prev => !prev)}
                    color='info'
                    
                  />
                } label="Ursa Space Integration" />
        </Stack>
      </CardContent>
      <CardActions>
    </CardActions>
    </Card>)
  }

  export {Settings}