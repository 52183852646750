import { get, set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { indexedDBEffect } from '../GlobalState';




const DGVData = atom({
    key: 'DGVData',
    default: {},
    effects:[
        indexedDBEffect('dgvData')
    ]
  }); 

const DGVClickInfo = atom({
  key: 'DGVClickInfo',
  default: [], 
});

const DGVTargetLastEventType = atom({
  key: 'DGVTargetLastEventType',
  default: [], 
});

const DGVEventData = atom({
  key: 'DGVEventData',
  default: [], 
});

const DGVEventDataDetails = selector({
  key:"DGVEventDataDetails",
  get: ({get})=>{
    let data = get(DGVEventData);

    let vessel_type_main ={}
    let rfgl_latest_anomaly_event = {}
    let flag_country = {}

    let reduced ={vessel_type_main, rfgl_latest_anomaly_event, flag_country}
    console.log(`Selector: ${data}`)
    if (data.length > 0){
      let stats = data.reduce((acc, cv)=>{
          acc.vessel_type_main[cv.vessel_type_main] = (acc.vessel_type[cv.vessel_type_main]||0) + 1;
          acc.event_type[cv.rfgl_latest_anomaly_event] = (acc.rfgl_latest_anomaly_event[cv.rfgl_latest_anomaly_event]||0) + 1;
          acc.event_type[cv.flag_country] = (acc.flag_country[cv.flag_country]||0) + 1;
      }, reduced)
      return stats;
    } else {
      return reduced
    }
}
})

const DGVTargetLayerVisibility = atom({
  key: 'DGVTargetLayerVisibility',
  default: false, 
});

const DGVEventPlayback = atom({
  key: 'DGVEventPlayback',
  default: false, 
});


let dgvEventStats = (eventData) =>{

    let vessel_type_main ={}
    let rfgl_latest_anomaly_event = {}
    let flag_country = {}

    let reduced ={vessel_type_main, rfgl_latest_anomaly_event, flag_country}

    let stats = Object.values(eventData).reduce((acc, cv)=>{
          acc.vessel_type_main[cv.vessel_type_main] = (acc.vessel_type_main[cv.vessel_type_main]) ? acc.vessel_type_main[cv.vessel_type_main] + 1  :  1
          acc.rfgl_latest_anomaly_event[cv.rfgl_latest_anomaly_event||'PV NON EVENT'] = acc.rfgl_latest_anomaly_event[cv.rfgl_latest_anomaly_event||'PV NON EVENT'] ? acc.rfgl_latest_anomaly_event[cv.rfgl_latest_anomaly_event||'PV NON EVENT'] + 1 : 1
          acc.flag_country[cv.flag_country] = (acc.flag_country[cv.flag_country]) ? (acc.flag_country[cv.flag_country]) + 1 : 1;
          return acc
    }, reduced)
    
    return stats; 

}
      
    
      


  export {DGVData, DGVClickInfo, DGVTargetLastEventType, DGVEventData, DGVTargetLayerVisibility, DGVEventDataDetails,DGVEventPlayback, dgvEventStats}