import DeckGL from '@deck.gl/react';
import {BitmapLayer} from '@deck.gl/layers';
import {TileLayer} from '@deck.gl/geo-layers';
import SphericalMercator from '@mapbox/sphericalmercator'

  let merc =  new SphericalMercator({
    size: 512
  })


  const WeatherTileLayer = ({weatherAPIToken,weatherLayers,weatherStyles})=>{
    let tilelayer = new TileLayer({
    id: 'weather-layer',
    data:[],
    getTileData: async ({bbox,signal})=>{
        let {west, south, east, north} = bbox;
        
        let textBbox = merc.convert([west, south, east, north],'900913').join(',')
        //let textBbox = [south, west, north,east].join(',')
        let layersRequested = weatherLayers.map(d => d.Name).join(',')
        let stylesRequested = Object.values(weatherStyles).flat().join(',')


        let tileRequest = await fetch(`/ows/wms?service=WMS&request=GetMap&version=1.3.0&width=512&height=512&crs=EPSG:3857&bbox=${textBbox}&FORMAT=image/png&TRANSPARENT=true&EXCEPTIONS=application/vnd.ogc.se_inimage&layers=${layersRequested}&styles=${stylesRequested}`,
        {
            headers:{
                'spire-api-key':weatherAPIToken
            },
            signal
        })

        if(signal.aborted){
            return null;
        }

        let png = await tileRequest.blob()

        let image = URL.createObjectURL(png)


        return image
    },
    minZoom: 0,
    maxZoom: 19,
    tileSize: 512,  
    updateTriggers:{
        getTileData: [weatherLayers,weatherStyles]
    },
    renderSubLayers: props => {
      const {
        bbox: {west, south, east, north}
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north]
      });
    }
  });

  return tilelayer
}

export {WeatherTileLayer}