import {
    RecoilRoot,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
  import {PathLayer} from '@deck.gl/layers';


const getMaritimeHistoryData = async ({clickInfo, setMaritimeHistoryData, maritimeAPIToken, dates})=>
    {
            let vesselList = clickInfo
            let historyList = {}
            let {start,end} = dates

            for (vessel of vesselList) {
                try {
                    console.log('Getting History')
                    let positions_data = []


                    let response = await fetch(`/vessels/mmsi_${vessel.staticData.mmsi}/positions?&limit=5000&timestamp_after=${start}&timestamp_before=${end}`, {
                        headers:{
                            Authorization: `Bearer ${maritimeAPIToken}`
                        }
                    });
                    let data = await response.json();
                    let last_next = ''
                    positions_data = positions_data.concat(data.data)
                    
                    console.log(`${(data.paging.next ?? null) && (last_next != data.paging.next)}`)
                    while (data.data.length > 0) {
                        console.log(`${(data.paging.next ?? null) && (last_next != data.paging.next)}`)
                        console.log(data.data.length)
                        let response = await fetch(`/vessels/mmsi_${vessel.staticData.mmsi}/positions?&limit=5000&timestamp_after=${start}&timestamp_before=${end}&next=${data.paging.next}`, {
                            headers:{
                                Authorization: `Bearer ${maritimeAPIToken}`
                            }
                        });

                        data = await response.json();
                        positions_data = positions_data.concat(data.data)
                        if (last_next == data.paging.next) {
                            break;
                        }

                        
                    }

                    let positions = []
                    

                    for (position of positions_data){
                        positions.push([position.geometry.coordinates[0], position.geometry.coordinates[1]])
                    }
                    historyList[vessel.staticData.mmsi] = {vessel, path:positions}
                    
                } catch (e){
                    console.log(`Error fetching Maritime history for vessel:${JSON.stringify(vessel)} : ${JSON.stringify(e)}`);
                }
            }
            console.log(historyList)
            setMaritimeHistoryData(historyList)
    }

const getMaritimeHistoryLayer = ({maritimeHistoryData, setClickInfo})=>{

    const layer = new PathLayer({
        id: 'maritime-history-layer',
        data: Object.values(maritimeHistoryData),
        pickable: true,
        widthScale: 20,
        widthMinPixels: 2,
          onClick: (info, event)=>{
            let record = structuredClone(info.object.vessel)
            setClickInfo((prev)=> [...prev.filter( d=>d.staticData.mmsi != record.staticData.mmsi), record]);
        },
        getPath: d => d.path,
        getColor: d => [0, 0, 0],
        getWidth: d => 5
    });

    return layer
}

export {getMaritimeHistoryData, getMaritimeHistoryLayer, MaritimeHistoryData}