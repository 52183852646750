import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import {AviationClickInfo, AviationHistoryData} from './AviationState'
import {AviationAPIToken, DeckLayers} from '../GlobalState'
import {PathLayer} from '@deck.gl/layers';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import {getAviationHistory} from './AviationHistory'


let AviationCard = ({data}) => {
    const [aviationClickInfo, setAviationClickInfo] = useRecoilState(AviationClickInfo);
    const [aviationHistoryData, setAviationHistoryData] = useRecoilState(AviationHistoryData);
    const [aviationAPIToken] = useRecoilState(AviationAPIToken);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [tabValue, setTabValue] = React.useState(0);

    let handleChange = (event,  newValue)=>{
      setTabValue(newValue)
    }

    React.useEffect(()=>{
        if(aviationHistoryData){
        let data = Object.values(aviationHistoryData);

        const layer = new PathLayer({
        id: 'aviation-history-layer',
        data,
        pickable: true,
        widthScale: 20,
        widthMinPixels: 2,
        onClick: (info, event)=>{
          let {path, ...record} = structuredClone(info.object)
          setAviationClickInfo((prev)=>[...prev.filter(d => d.icao_address != record.icao_address), record]);
        },
        getPath: d => d.path,
        getColor: d => [255, 0, 0],
        getWidth: d => 5
        });

        setLayers((prev)=> [...prev.filter(d => d.id != 'aviation-history-layer'), layer]);
      } else {
        setLayers((prev)=> [...prev.filter(d => d.id != 'aviation-history-layer')])
      }
    }, [aviationHistoryData, aviationClickInfo])

    return (
        <Card sx={{overflow:'hidden'}}>
        <CardContent>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange}>
            {aviationClickInfo.map((d, i) => <Tab label={d.icao_address} value={i} key={i} /> )}
          </Tabs>
          </Box>
          {aviationClickInfo.map((d,i)=> (
          <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
          <TableContainer sx={{ minWidth: 250, maxHeight:550}}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell colSpan={2} align='center'>ICAO: {d.icao_address} / Tail Number: {d.tail_number}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Feature</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(d).map(([feature,value]) => (
                <TableRow
                  key={feature}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {feature}
                  </TableCell>
                  <TableCell align="right">{value}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box> ))}
        </CardContent>
        <CardActions>
            <Button onClick={()=> setAviationClickInfo([])}>Close</Button>
            <Button onClick={() => getAviationHistory({icao:aviationClickInfo.map(d=>d.icao_address).join(), aviationAPIToken, setAviationHistoryData})}>History</Button>
            <Button onClick={() => setAviationHistoryData({})}>Clear History</Button>
        </CardActions>
        </Card>
      )
    }

export {AviationCard}