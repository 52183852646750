import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
;
import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Portal from '@mui/base/Portal';
import { DeckLayers } from '../GlobalState';
import {ScatterplotLayer, IconLayer, LineLayer} from '@deck.gl/layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {MaritimeGWSAPIToken} from '../GlobalState'
import WMSCapabilities from 'wms-capabilities'
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { ErrorOutline, Rectangle } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import {MaritimeGWSTileLayer} from './MaritimeGWSTileLayer'
import {MaritimeGWSCard} from './MaritimeGWSCard.js'
import { MaritimeGWSCapabilities, MaritimeGWSClickInfo,MaritimeGWSCQLFilter, MaritimeGWSLayers, MaritimeGWSStyles } from './MaritimeGWSState';


let getCapabilities = async ({maritimeGWSAPIToken, setMaritimeGWSAPIToken}) => {
  try {
    let capabilities = await fetch(`https://services.exactearth.com/gws/wms?service=wms&version=1.3.0&request=GetCapabilities&authKey=${maritimeGWSAPIToken}`)
    let xml = await capabilities.text()
    if (xml == "Unauthorized") throw new Error('Error with Maritime GWS API Token')
    let capJSON = new WMSCapabilities().parse(xml);
    return capJSON
  }
  catch {
    setMaritimeGWSAPIToken('')
    console.log(`Error with Maritime GWS API token`)
  }
}


let MaritimeGWSDataComponent = () => {
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [maritimeGWSClickInfo, setMaritimeGWSClickInfo] = useRecoilState(MaritimeGWSClickInfo);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayMaritimeGWSOptions, setDisplayMaritimeGWSOptions] = useState(false)
    const [maritimeGWSAPIToken, setMaritimeGWSAPIToken] = useRecoilState(MaritimeGWSAPIToken);
    const [maritimeGWSCapabilities, setMaritimeGWSCapabiltiies] = useRecoilState(MaritimeGWSCapabilities);
    const [maritimeGWSLayers,setMaritimeGWSLayers] = useRecoilState(MaritimeGWSLayers);
    const [maritimeGWSStyles,setMaritimeGWSStyles] = useRecoilState(MaritimeGWSStyles);
    const [cqlfilter, setCQLFilter] = useRecoilState(MaritimeGWSCQLFilter);


    let handleDisplayMaritimeGWSOptions = () => {
      setDisplayMaritimeGWSOptions((prev)=> !prev)
    }

    let handleSelectMaritimeGWSLayer = (data)=>{
      if(maritimeGWSLayers.map(d=> d.Name).includes(data.Name)){
        setMaritimeGWSLayers((prev)=> [...prev.filter((d)=> d.Name != data.Name)] )
        setMaritimeGWSStyles((prev)=> {
          prev = structuredClone(prev)
          delete prev[data.Name]
          return prev
        })
        
      } else {
        setMaritimeGWSLayers((prev)=>{
          return [...prev, data]
        })
        setMaritimeGWSStyles((prev)=>{
          prev = structuredClone(prev)
          prev[data.Name] = [data.Style[0].Name]
          return prev
        })
      }
    }

    let handleSelectMaritimeGWSStyle = ({layer,style}) => {
      setMaritimeGWSStyles((prev)=>{
        prev = structuredClone(prev);

        if (prev[layer]){
          if(prev[layer].includes(style)){
            prev[layer] = prev[layer].filter((i) => i != style)
            if (prev[layer].length == 0){
               delete prev[layer]
            }
          }
          else {
            prev[layer] = [style]
          }
          return prev
        } else {
            prev[layer] = [style]
            return prev
        }
      })
    }

    
    useEffect(()=>{
      if (maritimeGWSLayers.length >= 1 & Object.values(maritimeGWSStyles).length >= 1){
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'maritimeGWS-layer'), MaritimeGWSTileLayer({maritimeGWSAPIToken,maritimeGWSLayers, maritimeGWSStyles, maritimeGWSClickInfo, setMaritimeGWSClickInfo, cqlfilter})]
        })
      } else {
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'maritimeGWS-layer')]
        })     
      }
    }, [maritimeGWSLayers, maritimeGWSStyles,cqlfilter])

    useEffect(()=>{

      if (!displayMaritimeGWSOptions & !maritimeGWSCapabilities){
        getCapabilities({maritimeGWSAPIToken, setMaritimeGWSAPIToken}).then(data=> {
          setMaritimeGWSCapabiltiies(data)
        }).catch((e)=> console.log( `Error Fetching Maritime GWS Capabilities: ${e}`))
      }

      if(displayMaritimeGWSOptions){
        getCapabilities({maritimeGWSAPIToken, setMaritimeGWSAPIToken}).then(data=> {
          setMaritimeGWSCapabiltiies(data)
        })

      }

    }, [displayMaritimeGWSOptions])

    if (maritimeGWSCapabilities) {
      wmsLayers = maritimeGWSCapabilities.Capability.Layer.Layer
    }

    
    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={handleDisplayMaritimeGWSOptions}>Maritime GWS</Button>
              {cardPortal && displayMaritimeGWSOptions ? <Portal container={cardPortal.current}>
              <Card sx={{height:`150px`}}>
                <CardContent>
                <TextField sx={{minWidth:"100%"}}
                    id="outlined-multiline-static"
                    label="CQL Filter"
                    multiline
                    rows={4}
                    defaultValue={cqlfilter}
                    onBlur={(event) => {
                      setCQLFilter(event.target.value);
                    }}
                  />
                </CardContent>
              </Card>
              <Card><CardContent>
              { maritimeGWSCapabilities ? 
              <React.Fragment>
                  {<Box>
                  <List sx={{overflowY:'auto', height:`300px`}}>
                  {wmsLayers.length > 0 ? wmsLayers.map((d,i)=> (
                                    
                                        <ListItem key={i} disablePadding
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={()=>handleSelectMaritimeGWSLayer(d)}
                                            checked={maritimeGWSLayers.map(l=> l.Name).includes(d.Name)}
                                          />
                                        }>
                                          <ListItemButton>
                                            <ListItemText primary={d.Title} onClick={()=>console.log(d.Name)}/>
                                          </ListItemButton>
                                        </ListItem>
                  )) : ''}
                                      </List>
                                      </Box>}
                  </React.Fragment> : '' }
            </CardContent></Card>
            { maritimeGWSLayers.length > 0 ? 
            <Card><CardContent>
                  <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 250,
                          '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {maritimeGWSLayers.map((sectionId) => (
                          <li key={`section-${sectionId.Name}`}>
                            <ul>
                              <ListSubheader>{sectionId.Title}</ListSubheader>
                              {sectionId.Style.map((item) => (
                                <ListItem key={`item-${sectionId.Name}-${item.Name}`}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={()=>handleSelectMaritimeGWSStyle({layer:sectionId.Name, style:item.Name})}
                                    checked={maritimeGWSStyles[sectionId.Name] ? maritimeGWSStyles[sectionId.Name].includes(item.Name) : false}
                                  />
                                }>
                                  <pre>    </pre>
                                  <ListItemText primary={`${item.Name}`} />
                                </ListItem>
                              ))}
                            </ul>
                          </li>
                        ))}
                    </List>

            </CardContent></Card>
            : ''}
          </Portal> : '' }

          {cardPortal && maritimeGWSClickInfo.length > 0 ? <Portal container={cardPortal.current}>
            <MaritimeGWSCard/>
          </Portal> : ''}
        </React.Fragment>)
     }
   
     export {MaritimeGWSDataComponent}