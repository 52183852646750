import { get, set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { indexedDBEffect } from '../GlobalState';
  

const GroundStationLocations = atom({
key: 'GroundStationLocations',
default: [],
effects: [
    indexedDBEffect('GroundStationLocations')
]
});

export {GroundStationLocations}
