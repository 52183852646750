import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';




const CurrentWeatherConditions = atom({
    key: 'CurrentWeatherConditions',
    default: null,
  }); 

  const ForecastWeatherConditions = atom({
    key: 'ForecastWeatherConditions',
    default: null,
  }); 

  const RouteWeatherConditions = atom({
    key: 'RouteWeatherConditions',
    default: [],
  }); 

  const RouteMaritimeInsights = atom({
    key: 'RouteMaritimeInsights',
    default: [],
  });

  const MaritimeInsights = atom({
    key: 'MaritimeInsights',
    default: null,
  });

  const MaritimeInsightsVesselTypeSelection = atom({
    key: 'maritimeInsightsVesselTypeSelection',
    default: {vessel_type:"container_ship", vessel_state:"low_gm"},
  })

  const MaritimeInsightsType = atom({
    key: 'MaritimeInsightsType',
    default: "risk",
  }); 

export {CurrentWeatherConditions, ForecastWeatherConditions, RouteWeatherConditions, RouteMaritimeInsights,MaritimeInsights, MaritimeInsightsVesselTypeSelection, MaritimeInsightsType}