let PortEventsByLocation = ({location, maritimeAPIToken})=> {
    let myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${maritimeAPIToken}`);
    myHeaders.append("Content-Type", "application/json");

    let query = JSON.stringify({query: `query {
    portEventsByLocation(
          location:{
                  unlocode:${location.replace(" ","")},
              } 
        ) 
        {
            nodes{
            location{
                country
                centerPoint {
                    latitude
                    longitude
                }
                id
                name
                type
                unlocode
            }
            vessel {
                id
                staticData{
                    name
                    mmsi
                }
            }
            state
            ata
            atd
            draughtAta
            draughtAtd
            draughtChange
            duration {
                iso
            }
            timestamp
            updateTimestamp
        }
    }
  }`})


  let requestOptions = {
    //signal: abortMaritimeData.signal,
    method: 'POST',
    headers: myHeaders,
    body: query,
    redirect: 'follow',
    mode: 'cors',
  };

  return requestOptions

}

  export {PortEventsByLocation}