import * as React from 'react';
import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import {FeatureSpireSatellites, WeatherAPIToken} from './GlobalState'
import { CurrentWeatherConditions, ForecastWeatherConditions } from './Weather/WeatherState';
import { isDataLoading } from './GlobalState';
import { getCurrentWeatherConditions, getForecastWeatherConditions } from './Weather/getCurrentWeatherConditions';
import { GroundStationLocations } from './Satellites/SatelliteState';



let ContextMenu = ({contextMenu, setContextMenu}) => {
  let weatherAPIToken = useRecoilValue(WeatherAPIToken)
  let satelliteFeature = useRecoilValue(FeatureSpireSatellites)
  let setCurrentWeatherConditions = useSetRecoilState(CurrentWeatherConditions)
  let setForecastWeatherConditions = useSetRecoilState(ForecastWeatherConditions)
  let [isLoading, setIsLoading] = useRecoilState(isDataLoading)
  let setGroundStationLocations = useSetRecoilState(GroundStationLocations)

  let handleCurrentWeatherConditions = (info, event)=> {
    setContextMenu(null)
    setIsLoading(true)
    let conditions = getCurrentWeatherConditions({weatherAPIToken, coordinates:contextMenu.info.coordinate})
    
    conditions.then((conditions)=> {
        setIsLoading(false)
        setCurrentWeatherConditions(conditions)
        console.dir(conditions)
    })

  }

  let handleForecastWeatherConditions = (info, event)=> {
    setContextMenu(null)
    setIsLoading(true)
    let conditions = getForecastWeatherConditions({weatherAPIToken, coordinates:contextMenu.info.coordinate})
    
    conditions.then((conditions)=> {
        setIsLoading(false)
        setForecastWeatherConditions(conditions)
        console.dir(conditions)
    })

  }

  let handleSetGroundTarget = (info, event)=> {
    setContextMenu(null)

    let location = contextMenu.info.coordinate
    
    setGroundStationLocations(prev => [...prev, {name: (prev.length+1).toString(), location}])

  }


  return (
      <Menu open={contextMenu !== null}
      anchorReference="anchorPosition"
      anchorPosition={{left:contextMenu.info.x, top:contextMenu.info.y }}
      onClose={()=> setContextMenu(null)}
      >
        {weatherAPIToken && <MenuItem onClick={handleCurrentWeatherConditions}>Current Weather Conditions</MenuItem>}
        {weatherAPIToken &&<MenuItem onClick={handleForecastWeatherConditions}>Forecast Weather Conditions</MenuItem>}
        {satelliteFeature && <MenuItem onClick={handleSetGroundTarget}>Set Ground Target</MenuItem>}
      </Menu>
  );
}

export {ContextMenu}