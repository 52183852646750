import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import {atom, selector, useRecoilState, useRecoilValue} from 'recoil';
import { MaritimeDataComponent } from './Maritime/MaritimeInitialQuery';
import { Snackbar } from '@mui/material';
import {PageInfo, MaritimeData, StopMaritimeData} from './Maritime/MaritimeState'
import Switch from '@mui/material/Switch';
import { AviationDataComponent } from './Aviation/AviationQuery';
import { DGVDataComponent } from './DGV/DGVQuery';
import Stack from '@mui/material/Stack';
import LinearProgress from '@mui/material/LinearProgress';
import {DGVAPIToken, FeatureVesselDensity, isDataLoading, MaritimeGWSAPIToken, WeatherAPIToken} from './GlobalState';
import { WeatherDataComponent } from './Weather/WeatherQuery';
import { MaritimeAPIToken, AviationAPIToken, DGVAPIToken, WeatherAPIToken, FeatureURSASPACE, FeatureGNSSIndicators, FeatureSpireSatellites} from './GlobalState';
import { MaritimeGWSDataComponent } from './MaritimeGWS/MaritimeGWSQuery';
import { UrsaspaceDataComponent } from './Ursaspace/UrsaSpaceQuery';
import { GeometryEditor } from './Edit/Edit';
import { GnssIndicatorsComponent } from './GnssIndicators/GnssIndicators';
import { SatelliteDataComponent } from './Satellites/Satellites';
import { DensityTileLayer } from './DensityTiles/DensityTiles';


export default function DemoBottomBar() {
  const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
  const isLoading = useRecoilValue(isDataLoading)
  const maritimeAPIToken = useRecoilValue(MaritimeAPIToken)
  const aviationAPIToken = useRecoilValue(AviationAPIToken)
  const dgvAPIToken = useRecoilValue(DGVAPIToken)
  const weatherAPIToken = useRecoilValue(WeatherAPIToken)
  const maritimeGWSAPIToken = useRecoilValue(MaritimeGWSAPIToken)
  const [featureUrsa, setFeatureUrsa] = useRecoilState(FeatureURSASPACE)
  const [featureGNSSIndicators, setFeatureGNSSIndicators] = useRecoilState(FeatureGNSSIndicators)
  const [featureSpireSatellites, setFeatureSpireSatellites] = useRecoilState(FeatureSpireSatellites)
  const [featureVesselDensity, setFeatureVesselDensity] = useRecoilState(FeatureVesselDensity)


  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="fixed" sx={{ top: 'auto', bottom: 0 }}>
        {isLoading ? <LinearProgress /> : ''}
        <Toolbar variant="dense">
          <Stack direction='row' spacing={2}>
            {maritimeAPIToken ? <MaritimeDataComponent/> : ''}
            {maritimeGWSAPIToken ? <MaritimeGWSDataComponent/> : ''}
            {aviationAPIToken ? <AviationDataComponent/> : ''}
            {dgvAPIToken ? <DGVDataComponent/> : '' }
            {weatherAPIToken ? <WeatherDataComponent/> : ''}
            {featureUrsa ? <UrsaspaceDataComponent/> : ''}
            {featureGNSSIndicators ? <GnssIndicatorsComponent/>: ''}
            {featureSpireSatellites ? <SatelliteDataComponent/>: ''}
            {featureVesselDensity ? <DensityTileLayer/> : ''}
          </Stack>
        </Toolbar>
      </AppBar>
      <Snackbar
                open={pageInfo.snackOpen}
                anchorOrigin={{vertical:'bottom','horizontal':'center'}}
                autoHideDuration={6000}
                onClose={()=> setPageInfo((state)=> {
                  state = structuredClone(state)
                  state.snackOpen = false
                  state.message = null
                  return state; 
                })}
                message={pageInfo.message ? pageInfo.message : `Loading page ${pageInfo.count} of ${pageInfo.totalCount} / Record count: ${pageInfo.recordCount}`}
            />
    </Box>
  );
}