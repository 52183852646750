let getCurrentWeatherConditions = async ({ weatherAPIToken, coordinates }) => {
    let baseUrl = `/current/weather/point?lat=${coordinates[1]}&lon=${coordinates[0]}&unit_system=us-f`;

    try {
        let response = await fetch(baseUrl, {
            headers: { 'spire-api-key': weatherAPIToken }
        });

        cwc = await response.json();

        return cwc;
    } catch (e) {
        console.log(`Error fetching Current Weather Conditions: ${e}`);
    }
};

let getForecastWeatherConditions = async ({ weatherAPIToken, coordinates }) => {
    let baseUrl = `/forecast/point?lat=${coordinates[1]}&lon=${coordinates[0]}&unit_system=us-f`;

    try {
        let response = await fetch(baseUrl, {
            headers: { 'spire-api-key': weatherAPIToken }
        });

        cwc = await response.json();

        return cwc;
    } catch (e) {
        console.log(`Error fetching Forecast Weather Conditions: ${e}`);
    }
};

let getOptimizedPointForecast = async ({ weatherAPIToken,location, setPageInfo, type='unlocode'}) => {
    let baseUrl = `/forecast/point/optimized?location=${type}:${location.replace(" ","")}&unit_system=us-f`;

    try {
        let response = await fetch(baseUrl, {
            headers: { 'spire-api-key': weatherAPIToken }
        });

        opf = await response.json();

        if (!response.ok){
            console.dir(opf.message)
            let err = new Error(opf.message)
            throw err
        }

        

        return opf;
    } catch (e) {
        setPageInfo({message:`Optimized Point Forecast: ${e}`, snackOpen:true});
    }

}

export {getCurrentWeatherConditions, getForecastWeatherConditions, getOptimizedPointForecast}
