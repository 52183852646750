import { get, set } from 'idb-keyval';
import {useRef} from 'react';
import {
  RecoilRoot,
  atom,
  selector,
  useRecoilState,
  useRecoilValue,
} from 'recoil';
import { indexedDBEffect } from '../GlobalState';




const AviationData = atom({
    key: 'aviationData',
    default: {},
    effects:[
      indexedDBEffect('aviationData')
    ]
  });

  const AviationHistoryData = atom({
    key: 'aviationHistoryData',
    default: {},
  });

const AviationClickInfo = atom({
  key: 'aviationClickInfo',
  default: []
});

  export {AviationData, AviationHistoryData, AviationClickInfo}

