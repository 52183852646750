let getUrsaSpaceQuery = ({start, end}) =>  

 `{
    ursa_udp_analyticgeojsonrecord(
        limit:5000
        uploadTime: {
                gt: "${start}"
                lt: "${end}"
        }
        sortBy:{
            field: "uploadTime",
            sortOrder: "ASC"
        }
        content: {
            processors: {
                processorName: {
                    or: ["Vessel Detection"]
                }
            }
        }
    )
    {
        assignedId
        uploadTime
        content {
            processors {
                parameters 
            }
            sensorData {
                footprint
            }
            features {
                type
                geometry {
                    type
                    coordinates
                }
                properties
            }
            
        }
    }
}`

export {getUrsaSpaceQuery}