import DeckGL from '@deck.gl/react';
import {BitmapLayer} from '@deck.gl/layers';
import {TileLayer} from '@deck.gl/geo-layers';
import SphericalMercator from '@mapbox/sphericalmercator'
import { TurnedIn } from '@mui/icons-material';

  let merc =  new SphericalMercator({
    size: 512
  })

  const MaritimeGWSTileLayer = ({maritimeGWSAPIToken,maritimeGWSLayers,maritimeGWSStyles,setMaritimeGWSClickInfo,maritimeGWSClickInfo, cqlfilter})=>{
    let tilelayer = new TileLayer({
    id: 'maritimeGWS-layer',
    data:[],
    getTileData: async ({bbox,signal})=>{
        let {west, south, east, north} = bbox;

        let textBbox = merc.convert([west, south, east, north],'900913').join(',')
        let layersRequested = maritimeGWSLayers.map(d => d.Name).join(',')
        let stylesRequested = Object.values(maritimeGWSStyles).flat().join(',')

        let new_cqlfilter = cqlfilter
        if (cqlfilter && maritimeGWSLayers.length > 1 && !cqlfilter.includes(';')){
          for (let i =1; i < maritimeGWSLayers.length; i++){          
            new_cqlfilter+=`;${cqlfilter}`
          }
        }
        
        let tileRequest = await fetch(`gws/wms?service=WMS&version=1.3.0&request=GetMap&layers=${layersRequested}&styles=${stylesRequested}&bbox=${textBbox}&width=512&height=512&format=image/png&crs=EPSG:3857&TRANSPARENT=true&EXCEPTIONS=application/vnd.ogc.se_inimage&authKey=${maritimeGWSAPIToken}${cqlfilter !='' ? `&cql_filter=${new_cqlfilter}`: ''}`,
        {
            signal
        })

        if(signal.aborted){
            return null;
        }

        let png = await tileRequest.blob()

        let image = URL.createObjectURL(png)


        return image
    },
    minZoom: 0,
    maxZoom: 19,
    tileSize: 512,  
    updateTriggers:{
        getTileData: [maritimeGWSLayers,maritimeGWSStyles, cqlfilter]
    },
    pickable:true,
    onClick: async (data)=>{
      //console.dir(data)
        let [x,y] = data.bitmap.pixel
        let [west, south, east, north] = data.sourceLayer.props.bounds;

        let textBbox = merc.convert([west, south, east, north],'900913').join(',')
        //let textBbox = [south, west, north, east].join(',')
        let layersRequested = maritimeGWSLayers.map(d => d.Name).join(',')

        try {
          let featureInfoRequest = await fetch(`gws/ows?authKey=${maritimeGWSAPIToken}&service=WMS&SERVICE=WMS&VERSION=1.3.0&REQUEST=GetFeatureInfo&BBOX=${textBbox}&CRS=EPSG:3857&width=512&height=512&LAYERS=${layersRequested}&STYLES=&FORMAT=image/png&QUERY_LAYERS=${layersRequested}&INFO_FORMAT=application/json&I=${x}&J=${y}&FEATURE_COUNT=10${cqlfilter !='' ? `&cql_filter=${cqlfilter}`: ''}`)
          let feature = await featureInfoRequest.json()
          feature = feature.features.map(d=> d.properties)
          console.log(feature)
          setMaritimeGWSClickInfo(feature)
        } catch {
          console.log(`Error in GetFeatureInfo - Request`)
        }


    },
    renderSubLayers: props => {
      const {
        bbox: {west, south, east, north}
      } = props.tile;

      return new BitmapLayer(props, {
        data: null,
        image: props.data,
        bounds: [west, south, east, north]
      });
    }
  });

  return tilelayer
}

export {MaritimeGWSTileLayer}