import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import {PageInfo, MaritimeData, StopMaritimeData, MaritimeLayer, ClickInfo, HistoryStart, HistoryEnd, MaritimeHistoryData, MaritimePredictedRouteData,} from './MaritimeState'
import {CardPortal, DeckLayers, HoverInfo, MaritimeAPIToken, WeatherAPIToken, FeatureEnhancedVC, FeatureVesselToPortETA, FeatureHistoricalPositions} from '../GlobalState'
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import MaritimeCard from './MaritimeCard';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import vessel from 'data-url:./vessel.svg'
import chroma from 'chroma-js'
import { Stack } from '@mui/system';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { getMaritimePredictedRouteLayer } from './MaritimePredictedRoute';
import { getMaritimeHistoryData, getMaritimeHistoryLayer } from './MaritimeHistory';

import {IconLayer} from '@deck.gl/layers'
import { MaritimeInsightsVesselTypeSelection, MaritimeInsightsType } from '../Weather/WeatherState';
import { WorldPortIndexDisplay } from './WorldPortIndex';
import { HistorySharp } from '@mui/icons-material';

const ICON_MAPPING = {
  vessel: {x:0, y:0, width:12, height:24, mask:true}
}

let abortMaritimeData = new AbortController()

let maritimeInsightsVesselType = {
  container_ship: ["low_gm", "high_gm", "medium_gm"], 
  bulk_carrier: ["in_ballast", "loaded_small", "loaded_half", "loaded_full"],
  tanker: ["in_ballast", "loaded"],
  general_cargo: ["low_gm", "high_gm","medium_gm"]
}

let getAllVessels = ({nextCursor="", abortMaritimeData, maritimeAPIToken, searchParameters = {}, vesselToPortETA = false, route = false, evd = false}) => {
    let name = searchParameters?.name || '';
    let mmsi = searchParameters?.mmsi || '';
    let imo = searchParameters?.imo || '';
    let callsign = searchParameters?.callsign ||''



    let defaultStartDate = searchParameters?.lastPositionUpdateStart || new Date(new Date().setDate((new Date().getDate() - 1))).toISOString();
    let defaultEndDate = searchParameters?.lastPositionUpdateEnd || new Date().toISOString();

    let myHeaders = new Headers();
        myHeaders.append("Authorization", `Bearer ${maritimeAPIToken}`);
        myHeaders.append("Content-Type", "application/json");

    let variables = {"first":"1000", 
                      "after":nextCursor, 
                      "lastPositionUpdateStart":defaultStartDate,
                      "lastPositionUpdateEnd":defaultEndDate}

    
    for (const [k,v] of Object.entries(searchParameters)){
      if (v != '') {
        if (v?.length > 0 && typeof(v) != 'string') {
          variables[k] = v.map(d => parseInt(d)|| d)
        } else {
          variables[k] = ((k == 'lastPositionUpdateStart')||(k == 'lastPositionUpdateEnd')) ? v : parseInt(v) || v
        }
      }
    }


    
    
    let query = JSON.stringify(
      {query:`query($first: Int!, $after: String!, $lastPositionUpdateStart: DateTime!, $lastPositionUpdateEnd: DateTime!
        ${ name != '' ? `$name: [String!]`: ''}
        ${ callsign != '' ? `$callsign: [String!]`: ''}
        ${ mmsi != '' ? `$mmsi: [MMSI!]`: ''}
        ${ imo != '' ? `$imo: [IMO!]`: ''}
        )
       {  vessels(first: $first, 
                  after: $after,
                  ${ name != '' ? 'name: $name,' : ''}
                  ${ callsign != '' ? 'callsign: $callsign,' : ''}
                  ${ mmsi != '' ? 'mmsi: $mmsi,' : ''}
                  ${ imo != '' ? 'imo: $imo,' : ''}
                  lastPositionUpdate: {
                    startTime: $lastPositionUpdateStart
                    endTime: $lastPositionUpdateEnd
                  })  {
        pageInfo {
                hasNextPage
                endCursor    
                }    
          totalCount{ 
                  relation 
                  value 
               }    
          nodes {
                  staticData {
                              name
                              mmsi
                              imo
                              shipType
                              callsign
                    ${ evd ? `
                              shipSubType
                              flag
                    ` : ''}
                    
                    }      
                    lastPositionUpdate {
                              timestamp
                              latitude
                              longitude
                              collectionType
                              heading
                              course
                              speed
                                    }
                    ${ evd ? `
                    characteristics {
                      extended {
                        capacity {
                          deadweight
                          tpcmi
                          netTonnage
                          grossTonnage
                          displacement
                          liquidCubic98Percent
                          grainCubicCapacity
                          teu
                          holdCount
                          holdDimensions
                          hatchCount
                          hatchDimensions
                          feu
                          teuSurplus
                          teu14t
                          laneMeters
                          cars
                          passengers
                          reeferCubic
                        }
                        design {
                          isCoated
                          isGearless
                          isSelfUnloading
                          gearDisplay
                          gearMaxSwl
                          reeferPointCount
                          hullTypeCode
                        }
                        dimensions {
                          draught
                          lengthOverall
                          airDraught
                          keelToManifold
                          depth
                          beamMoulded
                          berthCount
                        }
                        history {
                          vesselNameDate
                          builtYear
                          deadYear
                          shipBuilder
                          hullNumber
                          registeredOwner
                          keelLaidYear
                          launchYear
                          commercialOwner 
                        }
                        propulsion {
                          mainEngineCount
                          mainEngineDesigner
                          propulsionType
                          engineDesignation
                          mcoRpm
                          mcoKw
                          mcoHp
                          propellerCount
                          propellerType
                          bowThrusterCount
                          sternThrusterCount
                        }
                        registration {
                          class1Code
                          class2Code
                          classDetails
                          isIceClassed
                          iceClass
                          #lastSpecialSurvey
                          #lastDryDock
                          certificates
                        }
                        vesselTypeAndTrading {
                          vesselSubtype
                          tradingCategoryCode
                          tradingStatusCode
                        }
                        bunker {
                          bunkers {
                            capacity
                            fuelTypeCode
                            fuelUnitCode
                            tankCount
                          }
                          range
                        }
                      }
                    }
                    `:''}
                    ${ vesselToPortETA ?
                      `currentVoyage{
                        predictedRoute{
                          eta
                          distance
                          duration {
                            iso
                          }
                          destinationPort{
                            name
                          }
                          ${ route ? ` 
                          waypoints {
                            geoJson{
                              coordinates
                            }
                          }
                          `: ''}  
                        }
                      }`
                    : ''}
                    }  
                  }
                }`,
              variables: variables})
  
    let requestOptions = {
      signal: abortMaritimeData.signal,
      method: 'POST',
      headers: myHeaders,
      body: query,
      redirect: 'follow',
      mode: 'cors',
    };
  
    return requestOptions
  }
  
  
  
  let getMaritimeData = async ({setMaritimeData, setPageInfo ,nextCursor="", abortMaritimeData = new AbortController(), maritimeAPIToken, searchParameters, vesselToPortETA = false, route = false, evd = false}) => {
    try {
    let response = await fetch("/graphql", getAllVessels({nextCursor, abortMaritimeData, maritimeAPIToken, searchParameters, vesselToPortETA, route, evd}))
    let result = await response.json();
    let data = result.data.vessels.nodes


    setMaritimeData((state) => {
      let priorData = structuredClone(state)
      for (record of data) {
            priorData[record.staticData.mmsi] = record;
      }  

      return priorData;
    })
      
    if (result.data.vessels.pageInfo.hasNextPage){
      if(typeof(setPageInfo) != "undefined" ){
        setPageInfo((state, props)=> {
              return {count: state.count + 1 , totalCount: Math.ceil(result.data.vessels.totalCount.value/1000), snackOpen:true, isLoading:true}
        });
      }

      return getMaritimeData({setMaritimeData, nextCursor:result.data.vessels.pageInfo.endCursor, setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters, vesselToPortETA});
    }
    else {
      return data;
    }
    }
    catch (e){
      console.log(`Error fetching Maritime Data:`, e)
    }
  }
  
  let [FISHING, TUG, DRY_BULK, CONTAINER, OTHER, PASSENGER, GENERAL_CARGO, TANKER_PRODUCT, CAR_CARRIER, PLEASURE_CRAFT, GAS_CARRIER, OFFSHORE, TANKER_CRUDE, PILOT_VESSEL, SEARCH_AND_RESCUE, VEHICLE_PASSENGER, ROLL_ON_ROLL_OFF, TANKER_CHEMICALS, SAILING, HIGH_SPEED_CRAFT, ANTI_POLLUTION, REEFER, MILITARY_OPS, DREDGER, LAW_ENFORCEMENT, LNG_CARRIER, PORT_TENDER, GENERAL_TANKER, DIVE_VESSEL, SPECIAL_CRAFT, LIVESTOCK] = chroma.scale(['blue','red']).colors(33, format='rgb')
  
  let vesselColors = {FISHING, TUG, DRY_BULK, CONTAINER, OTHER, PASSENGER, GENERAL_CARGO, TANKER_PRODUCT, CAR_CARRIER, PLEASURE_CRAFT, GAS_CARRIER, OFFSHORE, TANKER_CRUDE, PILOT_VESSEL, SEARCH_AND_RESCUE, VEHICLE_PASSENGER, ROLL_ON_ROLL_OFF, TANKER_CHEMICALS, SAILING, HIGH_SPEED_CRAFT, ANTI_POLLUTION, REEFER, MILITARY_OPS, DREDGER, LAW_ENFORCEMENT, LNG_CARRIER, PORT_TENDER, GENERAL_TANKER, DIVE_VESSEL, SPECIAL_CRAFT, LIVESTOCK}

  let end = new Date().toISOString()
  let start = new Date(new Date().setDate(new Date().getDate()-30)).toISOString()

  let MaritimeDataComponent= ()=>{
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
    const [maritimeData,setMaritimeData] = useRecoilState(MaritimeData);
    const [stopMaritimeData,setStopMaritimeData] = useRecoilState(StopMaritimeData);
    const [maritimeLayer,setMaritimeLayer] = useRecoilState(MaritimeLayer);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayMaritimeOptions, setDisplayMaritimeOptions] = useState(false)
    const [maritimePage,setMaritimePageInfo] = useRecoilState(PageInfo)
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);
    const [clickInfo, setClickInfo] = useRecoilState(ClickInfo);
    const [maritimeAPIToken] = useRecoilState(MaritimeAPIToken);
    const [intervalId, setIntervalId] = useState(0)
    const [syntheticStream, setSyntheticStream] = useState(false)
    const weatherAPIToken = useRecoilValue(WeatherAPIToken)
    const [maritimeInsightsVesselTypeSelection, setMaritimeInsightsVesselTypeSelection] = useRecoilState(MaritimeInsightsVesselTypeSelection)
    const [maritimeInsightsType,setMaritimeInsightsType] = useRecoilState(MaritimeInsightsType)
    const [worldPortLayer, setWorldPortLayer] = useState(false)
    const [startText, setStartText] = useRecoilState(HistoryStart)
    const [endText, setEndText] = useRecoilState(HistoryEnd)
    const [maritimeHistoryData, setMaritimeHistoryData] = useRecoilState(MaritimeHistoryData)
    const [maritimePredictedRouteData, setMaritimePredictedRouteData] = useRecoilState(MaritimePredictedRouteData)
    const featureHistoricalPositions = useRecoilValue(FeatureHistoricalPositions)
    const featureEnhancedVC = useRecoilValue(FeatureEnhancedVC)
    const featureVesselToPortEta = useRecoilValue(FeatureVesselToPortETA)
    const [vesselToPortEtaAvailable, setvesselToPortEtaAvailable] = useState('all-vessels')

    let handleChange = ()=> {
      setStopMaritimeData((state)=> {
        if(state) {
          abortMaritimeData.abort()
          abortMaritimeData = new AbortController()
          return !state;
        } else {
          searchParameters =  {}
          searchParameters.lastPositionUpdateStart = startText;
          searchParameters.lastPositionUpdateEnd = endText;

          getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken, vesselToPortETA:featureVesselToPortEta, searchParameters}).then(()=>{
            setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
            console.log("Data Fetch Complete")
          })
          return !state;
        }
      })
    }

    let handleSyntheticStream = ()=> {
      setMaritimeData({})
      setSyntheticStream((state)=> {
        if(state) {
          abortMaritimeData.abort()
          abortMaritimeData = new AbortController()
          window.clearInterval(intervalId);
          setIntervalId(0)

          return !state;
        } else {
          searchParameters =  {}
          searchParameters.lastPositionUpdateStart = new Date(new Date().setMinutes((new Date().getMinutes() - 1))).toISOString();

          getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters, vesselToPortETA:featureVesselToPortEta}).then(()=>{
            setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
            console.log("Data Fetch Complete")
          })
          
          if (intervalId == 0) {
            let id = window.setInterval(()=>
            {
            searchParameters =  {}
            searchParameters.lastPositionUpdateStart = new Date(new Date().setMinutes((new Date().getMinutes() - 1))).toISOString();
    
            getMaritimeData({setMaritimeData,setPageInfo, abortMaritimeData, maritimeAPIToken, searchParameters, vesselToPortETA:featureVesselToPortEta}).then(()=>{
              setPageInfo({count:0, snackOpen: false, isLoading:false, recordCount:0});
              console.log("Data Fetch Complete")
            })},300000)
            setIntervalId(id)
          }
          return !state;
      }
      })
    }

    let handleLayerChange = ()=> {
      setMaritimeLayer((state)=> {
        return !state;
      })
    }

    let handleMaritimeInsightsVesselStateChange = (event)=>{
      setMaritimeInsightsVesselTypeSelection((prev)=> {
        prev = structuredClone(prev)
        prev.vessel_state = event.target.value
        return prev
      })
    }

    let handleDisplayMaritimeOptions = () => {
      setDisplayMaritimeOptions(prev => !prev)
    }

    useEffect(()=> {
      setMaritimePageInfo((state)=>{
        let prior = structuredClone(state);
        prior.recordCount = Object.keys(maritimeData).length
        return prior
      })

      if(maritimeLayer) {
            let data = Object.values(maritimeData)

            if (vesselToPortEtaAvailable == "route-available") {
              data = data.filter(d=> d.currentVoyage?.predictedRoute != null)
            } else if (vesselToPortEtaAvailable == "no-route-available") {
              data = data.filter(d=> !d.currentVoyage?.predictedRoute)
            }
            
            const newlayer = 
            new IconLayer({id: 'maritime-layer', 
                        data,     
                        sizeScale:8,
                        pickable: true,
                        iconAtlas: vessel,
                        iconMapping: ICON_MAPPING,
                        sizeMaxPixels: 10,
                        sizeMinPixels: 6, 
                        getIcon: d => 'vessel',
                        getColor: d => vesselColors[d.staticData.shipType]||[0,0,0],
                        getPosition: value => [value.lastPositionUpdate.longitude, value.lastPositionUpdate.latitude], 
                        getAngle: value =>  360- value.lastPositionUpdate?.heading || 0,
                        onHover: (info,event) => {
                          setHoverInfo(info)
                        },
                        onClick: (info, event)=>{
                          let record = structuredClone(info.object)
                          setClickInfo((prev)=> [...prev.filter( d=>d.staticData.mmsi != record.staticData.mmsi), record]);
                        }
                      })
          ;
          setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-layer'), newlayer]);
        } else {
          setLayers((prev) => {
            return prev.filter(d => d.id != 'maritime-layer')
          });
        }
    }
  , [maritimeData,maritimeLayer, vesselToPortEtaAvailable]);

  React.useEffect(()=>{
    if(Object.keys(maritimeHistoryData).length > 0) {
      let layer = getMaritimeHistoryLayer({maritimeHistoryData, setClickInfo})

      setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-history-layer'), layer]);
    } else {
      setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-history-layer')])
    }
  }, [maritimeHistoryData])


  React.useEffect(()=>{
    if(Object.keys(maritimePredictedRouteData).length > 0) {
      let layer = getMaritimePredictedRouteLayer({maritimePredictedRouteData, setClickInfo})

      setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-predicted-route-layer'), layer]);
    } else {
      setLayers((prev)=> [...prev.filter(d => d.id != 'maritime-predicted-route-layer')])
    }
  }, [maritimePredictedRouteData])


   return (
     <React.Fragment>
        <Button variant="text" color="secondary" onClick={handleDisplayMaritimeOptions}>Maritime 2.0</Button>
        {cardPortal && displayMaritimeOptions ? <Portal container={cardPortal.current}>
        <Card sx={{order:-3}}><CardContent>
         <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={stopMaritimeData}
              onChange={handleChange}
              color='info'
              
            />
          } label="Maritime 2.0 Data" />
          </FormGroup>
          <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={syntheticStream}
              onChange={handleSyntheticStream}
              color='info'
              
            />
          } label="Start Synthetic Stream" />
          </FormGroup>
          <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={maritimeLayer}
              onChange={handleLayerChange}
              color='info'
              
            />
          } label="Maritime 2.0 Layer" />
          
          </FormGroup>
          <FormGroup>
          <FormControlLabel control={
            <Switch
              checked={worldPortLayer}
              onChange={()=> setWorldPortLayer(prev => !prev)}
              color='info'
              
            />
          } label="World Port Layer" />
          
          </FormGroup>
          </CardContent>
          </Card>
          {cardPortal && maritimeAPIToken && displayMaritimeOptions &&
        <Portal container={cardPortal.current}>
        <Card sx={{minWidth: 300, order:-2}}>
            <CardContent>
              <Stack spacing={3}>
              <FormControl variant="outlined">
                    <InputLabel htmlFor="start">Start Date</InputLabel>
                    <OutlinedInput 
                        id="start"
                        onChange={(event)=> setStartText(event.target.value)}
                        value={startText}
                    />
                </FormControl>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="end">End Date</InputLabel>
                    <OutlinedInput 
                        id="end"
                        onChange={(event)=> setEndText(event.target.value)}
                        value={endText}
                    />
                </FormControl>
                {featureVesselToPortEta ?
                <FormGroup> 
                <InputLabel id="demo-simple-select-label">VTP ETA Availability</InputLabel>
                <Select
                    value={vesselToPortEtaAvailable}
                    label="VTP ETA Availability"
                    onChange={(event)=>setvesselToPortEtaAvailable(event.target.value)}
                  >
                    <MenuItem value="route-available">Route Available</MenuItem>
                    <MenuItem value="no-route-available">No Route Available</MenuItem>
                    <MenuItem value="all-vessels">All Vessels</MenuItem>
                  </Select> 
                  </FormGroup>: ''}
                </Stack>
            </CardContent>
            </Card>
          </Portal> 
          }

          { weatherAPIToken != '' ?
          <Card sx={{order:-1}}>
          <CardContent>
          <InputLabel id="demo-simple-select-label">Maritime Insights API </InputLabel>
         <FormGroup>
         <InputLabel id="demo-simple-select-label">Maritime Insights Type</InputLabel>
         <Select
            value={maritimeInsightsType}
            label="Maritime Risk Type"
            onChange={(event)=>setMaritimeInsightsType(event.target.value)}
          >
            <MenuItem value="risk">Risk</MenuItem>
            <MenuItem value="efficiency">Efficiency</MenuItem>
            
          </Select>
         <br/>
         <InputLabel id="demo-simple-select-label">Vessel Type</InputLabel>
          <Select
            value={maritimeInsightsVesselTypeSelection.vessel_type}
            label="Vessel Type"
            onChange={(event)=>setMaritimeInsightsVesselTypeSelection({vessel_type:event.target.value, vessel_state:maritimeInsightsVesselType[event.target.value][0]})}
          >
            {Object.keys(maritimeInsightsVesselType).map(k => <MenuItem key={k} value={k}>{k}</MenuItem>)}
            
          </Select>
          <br/>
          <InputLabel id="demo-simple-select-label">Vessel Load State</InputLabel>
          <Select
            value={maritimeInsightsVesselTypeSelection.vessel_state}
            label="Vessel State"
            onChange={handleMaritimeInsightsVesselStateChange}
          >
            {maritimeInsightsVesselType[maritimeInsightsVesselTypeSelection.vessel_type].map(k => <MenuItem key={k} value={k}>{k}</MenuItem>)}
            
          </Select>
          </FormGroup>
          </CardContent>
          </Card>

          :''}
          </Portal> 
          : '' }
          {cardPortal && clickInfo.length > 0 ? <Portal container={cardPortal.current}>
            <MaritimeCard/>
          </Portal> : ''}
          {cardPortal && worldPortLayer == true && 
          <Portal container={cardPortal.current}>
            <WorldPortIndexDisplay setHoverInfo={setHoverInfo}/>
          </Portal>
          }
          </React.Fragment>)
  } 

  export {MaritimeDataComponent, getMaritimeData} 