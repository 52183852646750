import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';
import React, { useState, useEffect } from 'react';
import api from "./iata-icao.csv"
import { DeckLayers, MaritimeAPIToken } from '../GlobalState';
import {ScatterplotLayer} from '@deck.gl/layers';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {WeatherAPIToken} from '../GlobalState'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { getOptimizedPointForecast } from '../Weather/getCurrentWeatherConditions';
import { ForecastWeatherConditions } from '../Weather/WeatherState';
import { PageInfo } from '../Maritime/MaritimeState';
import { HoverInfo } from '../GlobalState';




let AirportDisplay = ({setHoverInfo})=>{
    const [layers,setLayers] = useRecoilState(DeckLayers)
    const [selectedPort, setSelectedPort] = useState(null)
    const weatherAPIToken = useRecoilValue(WeatherAPIToken)
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);
    const setForecastWeatherConditions = useSetRecoilState(ForecastWeatherConditions)
    const sethoverInfo = useSetRecoilState(HoverInfo)

    let handleGetOptimizedPointForecast = async ()=> {
        let forecast = await getOptimizedPointForecast({ weatherAPIToken,location:selectedPort.icao, setPageInfo,type:'icao'})
        setForecastWeatherConditions(forecast)

    }

    useEffect(()=> {
              const newlayer = 
              new ScatterplotLayer({id: 'airport-index-layer', 
                          data: api,
                          pickable: true,
                          stroked: true,
                          filled: true,
                          radiusScale: 6,
                          radiusMinPixels: 1,
                          radiusMaxPixels: 100,
                          lineWidthMinPixels: 1,
                          getFillColor: d => [0,0,0],
                          getLineColor: d => [0,0,0],
                          getPosition: value => [parseFloat(value.longitude), parseFloat(value.latitude)],
                          onHover: (info, event) => {
                            if(info.object) {
                                info.object.message = info.object.airport; 
                                sethoverInfo(info)
                            } else {
                                sethoverInfo(info)
                            }
                          },
                          onClick: (info, event) => setSelectedPort(info.object),
                        })
            ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'airport-index-layer'), newlayer]);
        return ()=>{
            setLayers((prev) => {
              return prev.filter(d => d.id != 'airport-index-layer')
            });
          }
        }, [])


        if (selectedPort) {    
            return (
                <Card sx={{overflow:'hidden', maxWidth:550}}>
                    <CardContent>
                    <TableContainer sx={{ minWidth: 400, maxHeight:550}}>
                    <Table stickyHeader>
                        <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} align='center'>{selectedPort.airport} <br/> {selectedPort.icao} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  align='center'>Feature</TableCell>
                            <TableCell  align='center'>Value</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Object.entries(selectedPort).map(([feature,value]) => (
                            <TableRow
                            key={feature}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {feature}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {value}
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </CardContent>
                    <CardActions sx={{flexDirection:'column'}}>
                        <Button onClick={handleGetOptimizedPointForecast}>Get Optimized Point Forecast</Button>
                        <Button onClick={()=> setSelectedPort(null)}>Close</Button>
                    </CardActions>
                </Card>
              )
        }

}

export {AirportDisplay}