import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import {AviationData, AviationClickInfo} from './AviationState'
import { DeckLayers, HoverInfo } from '../GlobalState';
import {ScatterplotLayer, IconLayer, LineLayer} from '@deck.gl/layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {AviationCard} from './AviationCard'
import {AviationAPIToken} from '../GlobalState'
import airplane from 'data-url:./airplane.svg'
import { AirportDisplay } from './AirportLocations';
import chroma from 'chroma-js'

let colors = chroma.scale(['blue','red']).colors(50, format='rgb')
let airlines = ['Southwest Airlines','American Airlines','Delta Air Lines','United Airlines','SkyWest Airlines','China Eastern Airlines','IndiGo Airlines','China Southern Airlines','Turkish Airlines','Air China','Ryanair','JetBlue Airways','Republic Airlines','Spirit Airlines','Malta Air','British Airways','Alaska Airlines, Inc.','FedEx Express','All Nippon Airways','Lufthansa','Envoy Air','LATAM Brasil','Shenzhen Airlines','Hainan Airlines','Azul Linhas AÃ©reas Brasileiras','easyJet UK','Endeavor Air','Gol Transportes AÃ©reos','Sichuan Airlines','Air France','PSA Airlines','Xiamen Airlines','United Parcel Service','Frontier Airlines','Japan Airlines Domestic','Volaris','Qatar Airways','Emirates Airlines','Vueling Airlines','Air Canada','Air New Zealand','Shandong Airlines','Wizz Air','easyJet Europe','Saudia','Avianca','Air Canada Jazz','Qantas','Air India Limited','Pegasus Airlines']
let airlineColors = {}
airlines.forEach((d,v) => airlineColors[d]= colors[v])



let aviationAbortController = new AbortController();

const ICON_MAPPING = {
  airplane: {x:0, y:0, width:24, height:10, mask:true}
}

let extractJSON = (string) => {
    const results = string.match(/\{(?:[^{}])*\}/g);
    return results || [];
  }

let aviationStream = async ({setAviationData, signal, aviationAPIToken, bounds = [-180,-90,180,90]}) => {
  if (bounds){
    latitude_between = `${bounds[1]},${bounds[3]}`
    longitude_between = `${bounds[0] < -180 ? -180 : bounds[0] },${bounds[2] > 180 ? 180 : bounds[2] }`
  }
  try {
    let response = await fetch(
        `https://api.airsafe.spire.com/v2/targets/stream?compression=none&${bounds ? `&latitude_between=${latitude_between}&longitude_between=${longitude_between}` :''}`,
        {
          signal,
          headers: {
            Authorization: aviationAPIToken,
        },
        }
      )

      const stream = response.body.getReader();

      let AviationMap = {}
    
      while (true) {
            const {value, done} = await stream.read();
            if (done) {
                break;
            }
      
            try {
                extractJSON(new TextDecoder("utf-8").decode(value)).forEach(
                    (parsed)=> {
                        if (parsed.indexOf("icao_address") > 0) {
                            const record = JSON.parse(parsed);
                            AviationMap[record['icao_address']] = record;
                          }
                    }
                ,[]);
                setAviationData((prevData)=>  {
                    let prevMap = structuredClone(prevData);
                    
                    for ( const [key,values] of Object.entries(AviationMap)) {
                        prevMap[key] = values;
                        
                    }
                    
                        return prevMap;
                    }
                );
            } catch (e) {
                console.log(`An error occured while parsing stream results: ${e}`);
            }
    }
  } catch (e) {
    console.log(`Unable to connect to Aviation Stream: ${e}`)
    return;
  }
    }

let aviationClickHandler = (info)=>{
  let record = structuredClone(info.object)
  setAviationClickInfo((prev)=> {
    let prevData = [...prev.filter(d => record.icao_address != d.icao_address), record]
    return prevData;
  });
}

let AviationDataComponent = ()=>{
    const [aviationData,setAviationData] = useRecoilState(AviationData);
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [visibilty, setVisibility] = useState(false);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayAviationOptions, setDisplayAviationOptions] = useState(false)
    const [isAviationStream, setIsAviationStream] = useState(false)
    const [aviationClickInfo, setAviationClickInfo] = useRecoilState(AviationClickInfo);
    const [aviationAPIToken] = useRecoilState(AviationAPIToken);
    const [airportLocations, setAirportLocationLayer] = useState(false)
    const setHoverInfo = useSetRecoilState(HoverInfo)
    const [bounds,setBounds] = useState(null)

    let aviationClickHandler = (info)=>{
      let record = structuredClone(info.object)
      
      setAviationClickInfo((prev)=> {
        let prevData = [...prev.filter(d => record.icao_address != d.icao_address), record]
        return prevData;
      });
    }
    
    let handleDisplayAviationOptions = () => {
      setDisplayAviationOptions(prev => !prev)
    }

    let handleChange = ()=> {
        setVisibility((prev) => !prev)
    }

    let handleAbort = ()=>{
         aviationAbortController.abort()
         aviationAbortController = new AbortController();
    }   

    let handleAviationStream = () => {
        console.dir(layers)
        setIsAviationStream((prev)=>{
          if(prev){
            handleAbort()
            setBounds([])
          } else {
            let bounds = layers.filter(d => d.id == 'aviation-layer')[0]?.context.viewport.getBounds()||[-180,-90,180,90]
            setBounds(bounds)
            setAviationData({}) 
            aviationStream({setAviationData, signal:aviationAbortController.signal, aviationAPIToken, bounds})
          }
          return !prev;
        })
    }

    if (bounds){
      let round_bounds = bounds.map(d => Math.round(d*100)/100)

      latitude_between = `${round_bounds[1]},${round_bounds[3]}`
      longitude_between = `${round_bounds[0] < -180 ? -180 : round_bounds[0] },${round_bounds[2] > 180 ? 180 : round_bounds[2] }`
    }

    useEffect(()=>{
              if(visibilty){
                const newlayer = 
                    new IconLayer({id: 'aviation-layer', 
                                data: Object.values(aviationData),
                                sizeScale:5,
                                iconAtlas: airplane,
                                iconMapping:ICON_MAPPING,
                                getIcon: d => 'airplane',
                                pickable: true,
                                getColor: d => airlineColors[d.airline_name]|| [0,0,0],
                                getPosition: (value) =>  [value.longitude, value.latitude, value.altitude_baro / .3048], 
                                getAngle: value => 360 - value.heading,
                                onHover: (info,event) => setHoverInfo(info),
                                onClick: (info, event)=> aviationClickHandler(info)
                              })
                  ;
                setLayers((prev)=> [...prev.filter(d => d.id != 'aviation-layer'), newlayer]);
            } else {
                setLayers((prev) => {
                  prev = prev.filter(d => d.id != 'aviation-layer')
                  return prev
                });
            }
    },[visibilty,aviationData])

    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={handleDisplayAviationOptions}>Aviation</Button>
              {cardPortal && displayAviationOptions ? <Portal container={cardPortal.current}>
              <Card><CardContent>
                <Stack spacing={2}>
                  {`Aviation Data: ${Object.keys(aviationData||{}).length}`}
                  <br/>
                  {isAviationStream ? <div> { `Bounds set to:`}<br/>{`Latitude Between: ${latitude_between}`}<br/>{`Longitude Between: ${longitude_between}`}</div>  : ''}
                  
                <FormGroup>
                <FormControlLabel control={
                  <Switch
                    checked={isAviationStream}
                    onChange={handleAviationStream}
                    color='info'
                    
                  />
                } label="Aviation Stream" />
                <FormControlLabel control={
                  <Switch
                    checked={visibilty}
                    onChange={handleChange}
                    color='info'
                    
                  />
                } label="Aviation Layer" />
                <FormControlLabel control={
                  <Switch
                    checked={airportLocations}
                    onChange={()=> setAirportLocationLayer(prev => !prev)}
                    color='info'
                    
                  />
                } label="Airport Location Layer" />
                </FormGroup>
                </Stack>
            </CardContent></Card>
            </Portal> : '' }
            { cardPortal && aviationClickInfo.length ? <Portal container={cardPortal.current}>
                <AviationCard/>
            </Portal> : '' }
            {cardPortal && airportLocations == true && 
              <Portal container={cardPortal.current}>
                <AirportDisplay setHoverInfo={setHoverInfo}/>
              </Portal>
          }
        </React.Fragment>)
     }
   
     export {AviationDataComponent}

