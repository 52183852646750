import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
  } from 'recoil';
;
import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { autocompleteClasses, cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import { DeckLayers } from '../GlobalState';
import {ScatterplotLayer, IconLayer, LineLayer} from '@deck.gl/layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {WeatherAPIToken} from '../GlobalState'
import WMSCapabilities from 'wms-capabilities'
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListSubheader from '@mui/material/ListSubheader';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { Rectangle } from '@mui/icons-material';
import Checkbox from '@mui/material/Checkbox';
import { WeatherTileLayer } from './WeatherTileLayer';
import { CurrentWeatherConditions, ForecastWeatherConditions, RouteWeatherConditions, RouteMaritimeInsights, MaritimeInsights } from './WeatherState';
import { CurrentWeatherConditionsDisplay } from './CurrentWeatherConditions';
import { ForecastWeatherConditionsDisplay } from './ForecastWeatherConditions';
import {RouteWeatherForecastLayer, RouteMaritimeInsightsLayer, MaritimeInsightsCard} from './RouteWXForecast'
import { HoverInfo } from '../GlobalState';

let getCapabilities = async ({weatherAPIToken}) => {
  let capabilities = await fetch(`/ows/wms?service=WMS&request=GetCapabilities`,{headers:{'spire-api-key': weatherAPIToken}})
  let xml = await capabilities.text()
  let capJSON = new WMSCapabilities().parse(xml);
  return capJSON
}


let WeatherDataComponent = () => {
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayWeatherOptions, setDisplayWeatherOptions] = useState(false)
    const weatherAPIToken = useRecoilValue(WeatherAPIToken);
    const [weatherCapabilities, setWeatherCapabiltiies] = useState(null)
    const [tabValue, setTabValue] = React.useState(0);
    const [subTabValue, setSubTabValue] = React.useState(0);
    const [weatherLayers,setWeatherLayers] = React.useState([])
    const [weatherStyles,setWeatherStyles] = React.useState({})
    const [currentWeatherConditions, setCurrentWeatherConditions] = useRecoilState(CurrentWeatherConditions)
    const forecastWeatherConditions = useRecoilState(ForecastWeatherConditions)
    const [routeWXForecast,setRouteWXForecast] = useRecoilState(RouteWeatherConditions)
    const [routeMaritimeInsights,setRouteMaritimeInsights] = useRecoilState(RouteMaritimeInsights)
    const [maritimeInsights,setMaritimeInsights] = useRecoilState(MaritimeInsights)
    const [hoverInfo, setHoverInfo] = useRecoilState(HoverInfo);

    let handleDisplayWeatherOptions = () => {
      setDisplayWeatherOptions((prev)=> !prev)
    }

    let handleChange = (event,  newValue)=>{
      setTabValue(newValue)
    }

    let handleSubTabChange = (event,  newValue)=>{
      setSubTabValue(newValue)
    }

    let handleSelectWeatherLayer = (data)=>{
      if(weatherLayers.map(d=> d.Name).includes(data.Name)){
        setWeatherLayers((prev)=> [...prev.filter((d)=> d.Name != data.Name)] )
        setWeatherStyles((prev)=> {
          prev = structuredClone(prev)
          delete prev[data.Name]
          return prev
        })
        
      } else {
        setWeatherLayers((prev)=>{
          return [...prev, data]
        })
        setWeatherStyles((prev)=>{
          prev = structuredClone(prev)
          prev[data.Name] = [data.Style[0].Name]
          return prev
        })
      }
    }

    let handleSelectWeatherStyle = ({layer,style}) => {
      setWeatherStyles((prev)=>{
        prev = structuredClone(prev);

        if (prev[layer]){
          if(prev[layer].includes(style)){
            prev[layer] = prev[layer].filter((i) => i != style)
            if (prev[layer].length == 0){
               delete prev[layer]
            }
          }
          else {
            prev[layer] = [style]
          }
          return prev
        } else {
            prev[layer] = [style]
            return prev
        }
      })
    }

    useEffect(()=>{
      if (weatherLayers.length >= 1 & Object.values(weatherStyles).length >= 1){
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-layer'), WeatherTileLayer({weatherAPIToken,weatherLayers, weatherStyles})]
        })
      } else {
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-layer')]
        })     
      }
    }, [weatherLayers, weatherStyles])

    useEffect(()=>{
      if (routeWXForecast.length >= 1){
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-route-layer'), RouteWeatherForecastLayer({routeWXForecast, setCurrentWeatherConditions, setHoverInfo})]
        })
      } else {
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-route-layer')]
        })     
      }
    }, [routeWXForecast])

    useEffect(()=>{
      if (routeMaritimeInsights.length >= 1){
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-insights-layer'), RouteMaritimeInsightsLayer({routeMaritimeInsights, setMaritimeInsights, setHoverInfo})]
        })
      } else {
        setLayers((prev)=>{
          return [...prev.filter(d=> d.id != 'weather-insights-layer')]
        })     
      }
    }, [routeMaritimeInsights])

    useEffect(()=>{
      if (!displayWeatherOptions & !weatherCapabilities){
        getCapabilities({weatherAPIToken}).then(data=> {
          setWeatherCapabiltiies(data)
          setTabValue(0)
          setSubTabValue(0)
        }).catch((e)=> console.log( `Error Fetching Weather Capabilities: ${e}`))
      }

      if(displayWeatherOptions){
        getCapabilities({weatherAPIToken}).then(data=> {
          setWeatherCapabiltiies(data)
          setTabValue(0)
          setSubTabValue(0)
        })

      }

    }, [displayWeatherOptions])

    if (weatherCapabilities) {
      try {
        wmsLayers = weatherCapabilities.Capability.Layer.Layer[0].Layer
      } catch {
        wmsLayers = []
      }
    }

    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={handleDisplayWeatherOptions}>Weather</Button>
              {cardPortal && currentWeatherConditions != null
                && <Portal container={cardPortal.current}>
                        <CurrentWeatherConditionsDisplay/>
                    </Portal>}
              {cardPortal && forecastWeatherConditions != null
                && <Portal container={cardPortal.current}>
                        <ForecastWeatherConditionsDisplay/>
                    </Portal>}
              {cardPortal && maritimeInsights != null
                && <Portal container={cardPortal.current}>
                        <MaritimeInsightsCard/>
                    </Portal>}
              {cardPortal && displayWeatherOptions ? <Portal container={cardPortal.current}>
              <Card><CardContent>
              { weatherCapabilities ? 
              <React.Fragment>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                  <Tabs value={tabValue} onChange={handleChange}>
                    {wmsLayers.map((d, i) =><Tab label={d.Title} value={i} key={i} />)}
                  </Tabs>
              </Box>
                  { wmsLayers.map((d,i)=> (
                  <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
                                {
                                    <Tabs value={subTabValue} onChange={handleSubTabChange}>
                                    {d.Layer.map((d, v) => <Tab label={d.Title} value={v} key={v} /> )}
                                    </Tabs>
                                }
                              </Box> ))}
                  <Box>
                                      <List sx={{overflowY:'auto', height:`300px`}}>
                  {wmsLayers.length > 0 ? wmsLayers[tabValue].Layer[subTabValue].Layer.map((d,i)=> (
                                    
                                        <ListItem key={i} disablePadding
                                        secondaryAction={
                                          <Checkbox
                                            edge="end"
                                            onChange={()=>handleSelectWeatherLayer(d)}
                                            checked={weatherLayers.map(l=> l.Name).includes(d.Name)}
                                          />
                                        }>
                                          <ListItemButton>
                                            <ListItemText primary={d.Title} onClick={()=>console.log(d.Name)}/>
                                          </ListItemButton>
                                        </ListItem>
                  )) : ''}
                                      </List>
                                    </Box>
                  </React.Fragment> : '' }
            </CardContent></Card>
            { weatherLayers.length > 0 ? 
            <Card><CardContent>
                  <List
                        sx={{
                          width: '100%',
                          bgcolor: 'background.paper',
                          position: 'relative',
                          overflow: 'auto',
                          maxHeight: 300,
                          '& ul': { padding: 0 },
                        }}
                        subheader={<li />}
                      >
                        {weatherLayers.map((sectionId) => (
                          <li key={`section-${sectionId.Name}`}>
                            <ul>
                              <ListSubheader><b>{sectionId.Name.split('/')[1]} / {sectionId.Name.split('/')[2]}</b> : {sectionId.Title}</ListSubheader>
                              {sectionId.Style.map((item) => (
                                <ListItem key={`item-${sectionId.Name}-${item.Name}`}
                                secondaryAction={
                                  <Checkbox
                                    edge="end"
                                    onChange={()=>handleSelectWeatherStyle({layer:sectionId.Name, style:item.Name})}
                                    checked={weatherStyles[sectionId.Name] ? weatherStyles[sectionId.Name].includes(item.Name) : false}
                                  />
                                }>
                                  <pre>    </pre>
                                  <ListItemText primary={`${item.Name}`} />
                                </ListItem>
                              ))}
                            </ul>
                          </li>
                        ))}
                    </List>

            </CardContent></Card>
            : ''}
            </Portal> : '' }
        </React.Fragment>)
     }
   
     export {WeatherDataComponent}