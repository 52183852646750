import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState,
  } from 'recoil';

import { get, set } from 'idb-keyval';
import React, { useState, useEffect } from 'react';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { cardActionAreaClasses } from '@mui/material';
import Portal from '@mui/base/Portal';
import { DeckLayers, HoverInfo } from '../GlobalState';
import {H3HexagonLayer} from '@deck.gl/geo-layers';
import {CardPortal} from '../GlobalState'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import chroma from 'chroma-js'
import {CSVLoader} from '@loaders.gl/csv';
import {parse, fetchFile} from '@loaders.gl/core';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';



let getColor = (percent) => {
    if (percent <=.02) {
        return [125, 255, 51]
    } else if (percent > .02 && percent <=.10){
        return [255, 252, 51 ]
    } else {
        return [223, 33, 33]
    }
}

let GnssIndicatorsComponent = ()=>{
    const [layers, setLayers] = useRecoilState(DeckLayers);
    const [cardPortal, setCardPortal] = useRecoilState(CardPortal);
    const [displayGnssOptions, setDisplayGnssOptions] = useState(false)
    const setHoverInfo = useSetRecoilState(HoverInfo)
    const [gnssIndicators, setGnssIndicators] = useState(false)
    const [source, setSource] = useState("/app/ais.csv")


    const handleChange = (event) => {
        setSource(event.target.value);
      };


    useEffect(()=>{
              if(gnssIndicators){
                const newlayer = 
                    new H3HexagonLayer({id: 'ais-gnss-indicators-layer', 
                                data: source,
                                loadOptions:{
                                    csv:{
                                        dynamicTyping:true,
                                        skipEmptyLines:true,
                                        header: true
                                    }
                                },
                                loaders:[CSVLoader],
                                pickable: true,
                                getFillColor: d => getColor(d.percent_bad),
                                getHexagon: d=> d.h3_index,
                                opacity: .2,
                                onClick: (event)=> console.log(event.object),
                              })
                  ;
                setLayers((prev)=> [...prev.filter(d => d.id != 'ais-gnss-indicators-layer'), newlayer]);
                } else {
                setLayers((prev) => {
                  prev = prev.filter(d => d.id != 'ais-gnss-indicators-layer')
                  return prev
                });
            }
    },[gnssIndicators, source])

    return (
        <React.Fragment>
              <Button variant="text" color="secondary" onClick={()=> setDisplayGnssOptions(prev => !prev)}>GNSS Indicators</Button>
              {cardPortal && displayGnssOptions ? <Portal container={cardPortal.current}>
              <Card><CardContent>
                <Stack spacing={2}>
                  
                <FormGroup>
                <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Source</InputLabel>
                    <Select
                    value={source}
                    label="Source"
                    onChange={handleChange}
                    >
                    <MenuItem value={"/app/ais.csv"}>AIS</MenuItem>
                    <MenuItem value={"/app/adsb.csv"}>ADS-B</MenuItem>
                    <MenuItem value={"/app/combined.csv"}>Combined</MenuItem>
                    </Select>
                </FormControl>
                <FormControlLabel control={
                  <Switch
                    checked={gnssIndicators}
                    onChange={()=> setGnssIndicators(prev => !prev)}
                    color='info'
                    
                  />
                } label="GNSS Indicators" />
                </FormGroup>
                </Stack>
            </CardContent></Card>
            </Portal> : '' }
        </React.Fragment>)
     }
   
     export {GnssIndicatorsComponent}