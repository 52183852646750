import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';
import React, { useState, useEffect } from 'react';
import wpi from "./UpdatedPub150.csv"
import { DeckLayers, MaritimeAPIToken } from '../GlobalState';
import {ScatterplotLayer} from '@deck.gl/layers';
import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Stack from '@mui/material/Stack';
import {WeatherAPIToken} from '../GlobalState'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import { PortEventsByLocation } from './PortEventsQuery';
import { getOptimizedPointForecast } from '../Weather/getCurrentWeatherConditions';
import { PageInfo } from './MaritimeState';




let getPortEvents = async ({location, maritimeAPIToken})=>{
    let response = await fetch("/graphql", PortEventsByLocation({location, maritimeAPIToken}))
    let result = await response.json();
    
    return result;
}

let WorldPortIndexDisplay = ({setHoverInfo})=>{
    const [layers,setLayers] = useRecoilState(DeckLayers)
    const [selectedPort, setSelectedPort] = useState(null)
    const maritimeAPIToken = useRecoilValue(MaritimeAPIToken)
    const weatherAPIToken = useRecoilValue(WeatherAPIToken)
    const [portEvents, setPortEvents] = useState(null)
    const [pageInfo,setPageInfo] = useRecoilState(PageInfo);

    let handleGetPortEvents = async ()=>{
        setPortEvents(await getPortEvents({location:selectedPort["UN/LOCODE"], maritimeAPIToken}))
        console.log(portEvents)
        
    }

    let handleGetOptimizedPointForecast = ()=> {
        getOptimizedPointForecast({location:selectedPort["UN/LOCODE"], weatherAPIToken, setPageInfo})
    }

    useEffect(()=> {
              const newlayer = 
              new ScatterplotLayer({id: 'port-index-layer', 
                          data: wpi,
                          pickable: true,
                          stroked: true,
                          filled: true,
                          radiusScale: 6,
                          radiusMinPixels: 1,
                          radiusMaxPixels: 100,
                          lineWidthMinPixels: 1,
                          getFillColor: d => [0,0,0],
                          getLineColor: d => [0,0,0],
                          getPosition: value => [parseFloat(value.Longitude), parseFloat(value.Latitude)],
                          onHover: (info, event) => setHoverInfo(info),
                          onClick: (info, event) => setSelectedPort(info.object),
                        })
            ;
            setLayers((prev)=> [...prev.filter(d => d.id != 'port-index-layer'), newlayer]);
        return ()=>{
            setLayers((prev) => {
              return prev.filter(d => d.id != 'port-index-layer')
            });
          }
        }, [])


        if (selectedPort) {    
            return (
                <Card sx={{overflow:'hidden', maxWidth:550}}>
                    <CardContent>
                    <TableContainer sx={{ minWidth: 400, maxHeight:550}}>
                    <Table stickyHeader>
                        <TableHead>
                        <TableRow>
                            <TableCell colSpan={2} align='center'>{selectedPort["Main Port Name"]} <br/> {selectedPort["UN/LOCODE"]} </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell  align='center'>Feature</TableCell>
                            <TableCell  align='center'>Value</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {Object.entries(selectedPort).map(([feature,value]) => (
                            <TableRow
                            key={feature}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell component="th" scope="row">
                                {feature}
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {value}
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </CardContent>
                    <CardActions sx={{flexDirection:'column'}}>
                        <Button onClick={handleGetPortEvents}>Get Port Events</Button>
                        <Button onClick={handleGetOptimizedPointForecast}>Get Optimized Forecast</Button>
                        <Button onClick={()=> setSelectedPort(null)}>Close</Button>
                    </CardActions>
                </Card>
              )
        }

}

export {WorldPortIndexDisplay}