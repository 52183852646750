import {
    RecoilRoot,
    atom,
    selector,
    useRecoilState,
    useRecoilValue,
    useSetRecoilState
  } from 'recoil';
import {React, useState, useEffect} from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Stack } from '@mui/system';
import { MaritimeAPIToken } from '../GlobalState';
import { MaritimeData, ClickInfo, MaritimeHistoryData } from '../Maritime/MaritimeState';
import { getMaritimeData } from '../Maritime/MaritimeInitialQuery';
import { DetectionEnd, DetectionStart } from './UrsaSpaceState';
import { getMaritimeHistoryData } from '../Maritime/MaritimeHistory';


let DetectionCard = ({data, setDetectionInfo, setVesselDetections})=> {
    const [tabValue, setTabValue] = useState(0);
    const [chip, setChip] = useState(null)
    const [imgError, setImgError] = useState(false)
    const setMaritimeData = useSetRecoilState(MaritimeData);
    const maritimeAPIToken = useRecoilValue(MaritimeAPIToken);
    const setClickInfo = useSetRecoilState(ClickInfo)
    const [start, setStartText] = useRecoilState(DetectionStart)
    const [end, setEndText] = useRecoilState(DetectionEnd)
    const [maritimeHistoryData, setMaritimeHistoryData] = useRecoilState(MaritimeHistoryData)
    const [priorVesselDetections, setPriorVesselDetections] = useState([]) 

    let handleChange = (event,  newValue)=>{
        setTabValue(newValue)
      }

    let handleMaritimeDataRequest = () => {
      let searchParameters = {
        mmsi:data.map(d=> d.aisMmsi).join(',')
      }
      getMaritimeData({setMaritimeData,maritimeAPIToken,searchParameters, evd:true}).then(
        (data)=> {
          setClickInfo((prev)=>{
            let prevData = structuredClone(prev);
            if (data.length >= 1){
                return [...prevData, ...data]
            } else {
                return prevData
            }           
          })
        }
      )
    }

    let handleHistoryRequest = ()=>{
      let dates = {start,end}
      let clickInfo = data.map(d=>  {return { staticData:{mmsi : d.aisMmsi}}})
      getMaritimeHistoryData({clickInfo, setMaritimeHistoryData, maritimeAPIToken,dates});
    }

    let setMMSIFilter = ()=>{
      setVesselDetections(prev => {
        setPriorVesselDetections(prev)
        return [...prev.filter(d=> d.properties.aisMmsi == data[0].aisMmsi )]
      })
    }

    let removeMMSIFilter = ()=>{
      setVesselDetections(prev => {
        setPriorVesselDetections([])
        return [...priorVesselDetections]
      })
    }

    

    return (
        <Card sx={{overflow:'hidden', maxWidth:550}}>
        <CardContent>
           <Stack sx={{alignItems:"center"}}>
            <img src={`https://gmss-public-images.s3.amazonaws.com/${data[0].region.replace(' ','-')}/${data[0].sarChipIdentifier}.png`} width={150} height={150} onError={(event)=> {console.log(event); event.target.style.display="none"}}/>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={tabValue} onChange={handleChange} variant="scrollable" scrollButtons={true} allowScrollButtonsMobile sx={{maxWidth:500}}>
            {data.map((d, i) => <Tab label={d.aisMmsi || "Uncorrelated Vessel"} value={i} key={i} /> )}
          </Tabs>
          </Box>
          {data.map((d,i)=> (
          <Box value={tabValue} index={i} key={i} hidden={tabValue !== i}>
          <TableContainer sx={{ minWidth: 250, maxHeight:550}}>
          <Table stickyHeader aria-label="simple table">
            <TableHead>
            <TableRow>
                <TableCell colSpan={2} align='center'>{d.aisMmsi ? `MMSI: ${d.aisMmsi}` : "Uncorrelated Vessel"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Feature</TableCell>
                <TableCell align="right">Value</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Object.entries(d).map(([feature,value]) => 
              (
                <TableRow
                  key={feature}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {feature}
                  </TableCell>
                  <TableCell align="right">{typeof(value) != 'object' ? value : 'object' }</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        </Box> ))}
        </CardContent>
        <CardActions sx={{flexDirection:'column'}}>
          {maritimeAPIToken && data[0].aisMmsi && <Button onClick={handleMaritimeDataRequest}>Get Maritime 2.0 Data</Button>}
          { data[0].aisMmsi && Object.keys(maritimeHistoryData).length == 0 ? <Button onClick={handleHistoryRequest}>Get AIS History</Button> : <Button onClick={()=> setMaritimeHistoryData({})}>Clear History</Button>}
          { data[0].aisMmsi && (priorVesselDetections.length  == 0 ? <Button onClick={setMMSIFilter}>Filter Detections by MMSI</Button> : <Button onClick={removeMMSIFilter}>Remove MMSI filter</Button>)}
          <Button onClick={()=>setDetectionInfo(null)}>Close</Button>
        </CardActions>
        </Card>
      )
}

export {DetectionCard}